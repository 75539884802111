import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames'

import Configs from '../../configs/Configs'

import firebase from 'firebase/app'
import '@firebase/firestore'
import 'firebase/auth'
import 'firebase/database'

import { createBrowserHistory } from 'history'
const history = createBrowserHistory({forceRefresh: true})

const styles = theme => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    minHeight: 'calc(100vh - '+Configs.layout.headerHeight+'px)',
    width: Configs.layout.menuSidebarWidth,
    backgroundColor: Configs.theme.sidebarBackground,
    color: Configs.theme.color,
    padding: '20px 0',
    position: 'relative'
  },
  sidemenu: {
    display: 'flex',
    alignItems: 'center',
    width: Configs.layout.menuSidebarWidth,
    padding: '0 38px',
    fontSize: '16px',
    lineHeight: '18px',
    color: '#9B9B9B',
    margin: '10px 0',
    height: '26px',
    cursor: 'pointer',
    ':hover': {
      color: '#FFFFFF'
    }
  },
  active: {
    boxShadow: 'inset 3px 0px #FF6059',
    color: '#FFFFFF'
  },
  sidemenuIcon: {
    marginRight: '16.25px'
  },
  logout: {
    height: '40px',
    background: '#262626',
    border: '1px solid #000000',
    boxSizing: 'border-box',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '9px',
    padding: '6px 10.52px 6px 8px'
  },
  hoveredLogout: {
    border: '1px solid #717171'
  },
  logoutContainer: {
    position: 'absolute',
    left: 0,
    bottom: '58px',
    width: '100%'
  },
  avatar: {
    border: '1px solid #000000',
    borderRadius: '45px'
  },
  username: {
    // fontFamily: 'Graphik',
    fontSize: '14px',
    lineHeight: '15px',
    color: '#9B9B9B',
    flexGrow: 1,
    marginLeft: '6px'
  }
})

class Sidemenu extends Component {

  constructor() {
    super()
    this.history = history
  }


  state = {
    hovered: -1,
    actived: 0,
    hoveredLogout: false
  }

  onMouseOver = (tid) => {
    this.setState({hovered: tid})
  }
  onMouseOut = (tid) => {
    this.setState({hovered: -1})    
  }

  hoverLogout = () => {
    this.setState({hoveredLogout: !this.state.hoveredLogout})
  }

  logout = () => {
    firebase.auth().signOut()
      .then(() => {
        localStorage.clear()
        this.history.push({
          pathname: '/'
        })
      })
      .catch(function (err) {
        // Handle errors
      });
  }

  render() {
    const { classes, photoURL, displayName } = this.props
    const { hovered, actived, hoveredLogout } = this.state
    return (
      <div className={classes.root}>
        <div className={actived === 0 || hovered === 0? classNames(classes.sidemenu, classes.active):classes.sidemenu} onMouseOver={() => this.onMouseOver(0)} onMouseOut={() => this.onMouseOut(0)}>
          <svg className={classes.sidemenuIcon} width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.75 1.2832C18.75 0.951683 18.6183 0.63374 18.3839 0.39932C18.1495 0.164899 17.8315 0.0332031 17.5 0.0332031H1.25C0.918479 0.0332031 0.600537 0.164899 0.366117 0.39932C0.131696 0.63374 0 0.951683 0 1.2832V3.1582C0 3.48972 0.131696 3.80767 0.366117 4.04209C0.600537 4.27651 0.918479 4.4082 1.25 4.4082H17.5C17.8315 4.4082 18.1495 4.27651 18.3839 4.04209C18.6183 3.80767 18.75 3.48972 18.75 3.1582V1.2832ZM1.25 14.9582C1.245 15.1273 1.27338 15.2957 1.33351 15.4538C1.39363 15.612 1.48433 15.7567 1.60042 15.8797C1.71651 16.0028 1.85571 16.1018 2.01006 16.171C2.16442 16.2402 2.3309 16.2783 2.5 16.2832H16.25C16.591 16.2718 16.9136 16.1261 17.1477 15.878C17.3818 15.6298 17.5084 15.2993 17.5 14.9582V5.6582H1.25V14.9582ZM7.5 8.1582H11.25C11.5815 8.1582 11.8995 8.2899 12.1339 8.52432C12.3683 8.75874 12.5 9.07668 12.5 9.4082C12.5 9.73972 12.3683 10.0577 12.1339 10.2921C11.8995 10.5265 11.5815 10.6582 11.25 10.6582H7.5C7.16848 10.6582 6.85054 10.5265 6.61612 10.2921C6.3817 10.0577 6.25 9.73972 6.25 9.4082C6.25 9.07668 6.3817 8.75874 6.61612 8.52432C6.85054 8.2899 7.16848 8.1582 7.5 8.1582Z" fill={actived === 0 || hovered === 0? "white":"#9B9B9B"}/>
          </svg>
          Projects
        </div>
        <div className={actived === 1 && hovered === 1? classNames(classes.sidemenu, classes.active):classes.sidemenu} onMouseOver={() => this.onMouseOver(1)} onMouseOut={() => this.onMouseOut(1)}>
          <svg className={classes.sidemenuIcon} width="19" height="15" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.9199 14.9567C11.5624 14.9567 11.2146 14.8402 10.9292 14.6248C10.6439 14.4094 10.4365 14.1068 10.3386 13.7629L8.28865 6.26293L6.88865 10.7567C6.78829 11.0748 6.59354 11.3548 6.33027 11.5595C6.067 11.7643 5.74766 11.8842 5.41466 11.9032C5.08167 11.9221 4.75078 11.8394 4.46593 11.6659C4.18109 11.4923 3.95577 11.2363 3.8199 10.9317L2.92615 8.95668L2.26365 10.2754C2.12192 10.5589 1.8734 10.7744 1.57276 10.8746C1.27212 10.9748 0.943975 10.9515 0.660525 10.8098C0.377075 10.6681 0.161534 10.4196 0.0613193 10.1189C-0.0388954 9.81827 -0.0155752 9.49013 0.12615 9.20668L1.4949 6.46918C1.63476 6.1915 1.85051 5.95918 2.11709 5.79919C2.38368 5.6392 2.69018 5.55809 3.001 5.56529C3.31183 5.57249 3.61424 5.6677 3.87314 5.83986C4.13203 6.01202 4.33679 6.25407 4.46365 6.53793L5.1824 8.13793L6.7699 3.06293C6.87324 2.7282 7.08159 2.43564 7.36412 2.22853C7.64665 2.02142 7.98835 1.91076 8.33865 1.91293H8.3699C8.72668 1.91947 9.07148 2.04283 9.35144 2.26409C9.6314 2.48535 9.83109 2.79231 9.9199 3.13793L11.8886 10.3067L14.0324 1.32543C14.1179 0.987722 14.3084 0.685901 14.5765 0.463513C14.8447 0.241125 15.1765 0.109669 15.5242 0.0881122C15.8719 0.0665558 16.2174 0.156014 16.511 0.343578C16.8045 0.531143 17.0309 0.807115 17.1574 1.13168L18.4074 4.54418C18.5185 4.84089 18.5071 5.16956 18.3758 5.4579C18.2446 5.74624 18.0041 5.97062 17.7074 6.08168C17.4107 6.19274 17.082 6.18138 16.7937 6.0501C16.5053 5.91883 16.281 5.67839 16.1699 5.38168L15.7449 4.25668L13.5199 13.7004C13.433 14.0527 13.2321 14.3663 12.9483 14.5925C12.6645 14.8186 12.3139 14.9444 11.9511 14.9504L11.9199 14.9567Z" fill={actived === 1 && hovered === 1? "white":"#9B9B9B"}/>
          </svg>
          Activity
        </div>
        <div className={actived === 2 && hovered === 2? classNames(classes.sidemenu, classes.active):classes.sidemenu} onMouseOver={() => this.onMouseOver(2)} onMouseOut={() => this.onMouseOut(2)}>
          <svg className={classes.sidemenuIcon} width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.1385 16.9555C14.8947 15.793 13.3947 14.5555 11.976 14.3555H11.6885C10.6002 15.4451 9.13424 16.074 7.59471 16.1117C6.05207 16.0714 4.58314 15.4431 3.48846 14.3555H3.21346C1.76346 14.543 0.26346 15.7617 0.0197098 16.943V17.0555C-0.00830809 17.2057 -0.00643821 17.3599 0.0252123 17.5094C0.0568628 17.6589 0.117673 17.8007 0.204165 17.9267C0.290657 18.0526 0.401133 18.1603 0.529275 18.2435C0.657417 18.3268 0.800711 18.3839 0.95096 18.4117C3.03688 18.7391 5.14578 18.898 7.25721 18.8867C9.5828 18.9032 11.905 18.7066 14.1947 18.2992C14.3452 18.2736 14.4891 18.2183 14.6182 18.1368C14.7472 18.0552 14.8588 17.9489 14.9465 17.824C15.0343 17.699 15.0964 17.558 15.1294 17.4089C15.1623 17.2598 15.1654 17.1057 15.1385 16.9555ZM2.69471 10.1742C2.76336 10.1794 2.83231 10.1794 2.90096 10.1742C3.85096 12.593 5.67596 14.218 7.56971 14.2242C9.46346 14.2305 11.2447 12.568 12.201 10.1367C12.282 10.1439 12.3636 10.1439 12.4447 10.1367C12.9052 10.0968 13.3317 9.87829 13.6331 9.52786C13.9345 9.17744 14.0867 8.72297 14.0572 8.26172C14.072 7.88102 13.9705 7.50484 13.7662 7.18327C13.5618 6.86171 13.2644 6.61002 12.9135 6.46172C12.9572 2.24922 10.6822 0.136719 7.56971 0.136719C4.45721 0.136719 2.17596 2.24922 2.21971 6.49297C1.87385 6.63934 1.57992 6.8862 1.376 7.20156C1.17207 7.51692 1.06754 7.88626 1.07596 8.26172C1.05627 8.49366 1.08256 8.72721 1.15332 8.94897C1.22408 9.17073 1.33791 9.37634 1.4883 9.55402C1.63869 9.7317 1.82266 9.87794 2.02969 9.98437C2.23671 10.0908 2.4627 10.1553 2.69471 10.1742Z" fill={actived === 2 && hovered === 2? "white":"#9B9B9B"}/>
          </svg>
          People
        </div>
        <div className={actived === 3 && hovered === 3? classNames(classes.sidemenu, classes.active):classes.sidemenu} onMouseOver={() => this.onMouseOver(3)} onMouseOut={() => this.onMouseOut(3)}>
          <svg className={classes.sidemenuIcon} width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.8072 3.565L9.56628 0.324091C9.48032 0.238136 9.36375 0.189848 9.24219 0.189848C9.12063 0.189848 9.00405 0.238136 8.9181 0.324091L5.67719 3.565C5.59124 3.65095 5.54295 3.76753 5.54295 3.88909C5.54295 4.01064 5.59124 4.12722 5.67719 4.21318L8.9181 7.45408C9.00405 7.54004 9.12063 7.58833 9.24219 7.58833C9.36375 7.58833 9.48032 7.54004 9.56628 7.45408L12.8072 4.21318C12.8931 4.12722 12.9414 4.01064 12.9414 3.88909C12.9414 3.76753 12.8931 3.65095 12.8072 3.565Z" fill={actived === 3 && hovered === 3? "white":"#9B9B9B"}/>
            <path d="M17.8072 8.565L14.5663 5.32409C14.4803 5.23814 14.3637 5.18985 14.2422 5.18985C14.1206 5.18985 14.0041 5.23814 13.9181 5.32409L10.6772 8.565C10.5912 8.65095 10.5429 8.76753 10.5429 8.88909C10.5429 9.01064 10.5912 9.12722 10.6772 9.21318L13.9181 12.4541C14.0041 12.54 14.1206 12.5883 14.2422 12.5883C14.3637 12.5883 14.4803 12.54 14.5663 12.4541L17.8072 9.21318C17.8931 9.12722 17.9414 9.01064 17.9414 8.88909C17.9414 8.76753 17.8931 8.65095 17.8072 8.565Z" fill={actived === 3 && hovered === 3? "white":"#9B9B9B"}/>
            <path d="M7.80718 8.565L4.56628 5.32409C4.48032 5.23814 4.36375 5.18985 4.24219 5.18985C4.12063 5.18985 4.00405 5.23814 3.9181 5.32409L0.677191 8.565C0.591237 8.65095 0.542948 8.76753 0.542948 8.88909C0.542948 9.01064 0.591237 9.12722 0.677191 9.21318L3.9181 12.4541C4.00405 12.54 4.12063 12.5883 4.24219 12.5883C4.36375 12.5883 4.48032 12.54 4.56628 12.4541L7.80718 9.21318C7.89314 9.12722 7.94143 9.01064 7.94143 8.88909C7.94143 8.76753 7.89314 8.65095 7.80718 8.565Z" fill={actived === 3 && hovered === 3? "white":"#9B9B9B"}/>
            <path d="M12.8072 13.565L9.56628 10.3241C9.48032 10.2381 9.36375 10.1898 9.24219 10.1898C9.12063 10.1898 9.00405 10.2381 8.9181 10.3241L5.67719 13.565C5.59124 13.651 5.54295 13.7675 5.54295 13.8891C5.54295 14.0106 5.59124 14.1272 5.67719 14.2132L8.9181 17.4541C9.00405 17.54 9.12063 17.5883 9.24219 17.5883C9.36375 17.5883 9.48032 17.54 9.56628 17.4541L12.8072 14.2132C12.8931 14.1272 12.9414 14.0106 12.9414 13.8891C12.9414 13.7675 12.8931 13.651 12.8072 13.565Z" fill={actived === 3 && hovered === 3? "white":"#9B9B9B"}/>
          </svg>
          Components
        </div>
        <div className={classes.logoutContainer}>
          <div className={hoveredLogout? classNames(classes.logout, classes.hoveredLogout):classes.logout} onMouseOver={this.hoverLogout} onMouseOut={this.hoverLogout}>
            <img className={classes.avatar} src={photoURL} width="28" height="28" alt="logout" />
            <label className={classes.username}>{displayName}</label>
            <svg onClick={this.logout} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g opacity={hoveredLogout? 0.8:0.5} filter="url(#filter0_d)">
                <path d="M10 0.625C8.1458 0.625 6.33324 1.17483 4.79153 2.20497C3.24982 3.23511 2.04821 4.69929 1.33863 6.41234C0.629062 8.1254 0.443406 10.0104 0.805142 11.829C1.16688 13.6475 2.05976 15.318 3.37088 16.6291C4.682 17.9402 6.35246 18.8331 8.17103 19.1949C9.98961 19.5566 11.8746 19.3709 13.5877 18.6614C15.3007 17.9518 16.7649 16.7502 17.795 15.2085C18.8252 13.6668 19.375 11.8542 19.375 10C19.375 7.5136 18.3873 5.12903 16.6291 3.37087C14.871 1.61272 12.4864 0.625 10 0.625ZM8.75 4.4125C8.75 4.08098 8.8817 3.76304 9.11612 3.52862C9.35054 3.2942 9.66848 3.1625 10 3.1625C10.3315 3.1625 10.6495 3.2942 10.8839 3.52862C11.1183 3.76304 11.25 4.08098 11.25 4.4125V9.5375C11.25 9.86902 11.1183 10.187 10.8839 10.4214C10.6495 10.6558 10.3315 10.7875 10 10.7875C9.66848 10.7875 9.35054 10.6558 9.11612 10.4214C8.8817 10.187 8.75 9.86902 8.75 9.5375V4.4125ZM14.4188 14.4125C13.2991 15.5822 11.7692 16.2717 10.1512 16.3358C8.53331 16.3999 6.95364 15.8336 5.74495 14.7561C4.53626 13.6787 3.7929 12.1742 3.67143 10.5596C3.54996 8.94493 4.05987 7.34616 5.09375 6.1C5.2993 5.83976 5.5998 5.67182 5.92916 5.63314C6.25852 5.59446 6.58976 5.68821 6.85 5.89375C7.11025 6.09929 7.27818 6.3998 7.31686 6.72916C7.35554 7.05852 7.2618 7.38976 7.05625 7.65C6.61997 8.20284 6.34818 8.86737 6.27201 9.56749C6.19583 10.2676 6.31835 10.975 6.62553 11.6088C6.93271 12.2425 7.41214 12.7769 8.00893 13.1509C8.60572 13.5248 9.29575 13.7231 10 13.7231C10.7043 13.7231 11.3943 13.5248 11.9911 13.1509C12.5879 12.7769 13.0673 12.2425 13.3745 11.6088C13.6817 10.975 13.8042 10.2676 13.728 9.56749C13.6518 8.86737 13.38 8.20284 12.9438 7.65C12.7382 7.38976 12.6445 7.05852 12.6831 6.72916C12.7218 6.3998 12.8898 6.09929 13.15 5.89375C13.4103 5.68821 13.7415 5.59446 14.0708 5.63314C14.4002 5.67182 14.7007 5.83976 14.9063 6.1C15.85 7.30068 16.3215 8.80538 16.2317 10.3299C16.1419 11.8545 15.497 13.2934 14.4188 14.375V14.4125Z" fill="white"/>
              </g>
              <defs>
                <filter id="filter0_d" x="0.625" y="0.625" width="18.75" height="19.75" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                  <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                  <feOffset dy="1"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"/>
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                </filter>
              </defs>
            </svg>
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(styles, {withTheme: true})(Sidemenu)