import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Sidemenu from './Sidemenu'
import Contentmenu from './Contentmenu'
import UpdateProject from './UpdateProject'

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%'
  }
})

class Content extends Component {

  editSpec = (sid) => {
    this.props.editSpec(sid)
  }

  deleteSpec = (sid) => {
    this.props.deleteSpec(sid)
  }

  handleSpecNameChange = (name) => {
    this.props.changeSpecName(name)
  }

  changeJsonFile = (data) => {
    this.props.changeJsonFile(data)
  }

  changeVideoFile = (data) => {
    this.props.changeVideoFile(data)
  }

  render() {
    const { classes, specData, onEdit, searchText, tempSpecName, pid, tempJson, tempVideo } = this.props
    return (
      <div className={classes.root}>
        {onEdit === -1?
          <>
            <Sidemenu />
            <Contentmenu specData={specData} searchText={searchText} pid={pid}
              deleteSpec={this.deleteSpec}
              editSpec={this.editSpec}/>
          </>
          :          
          <UpdateProject tempJson={tempJson} tempVideo={tempVideo}
            handleSpecNameChange={this.handleSpecNameChange}
            specData={specData}
            onEdit={onEdit}
            tempSpecName={tempSpecName}
            pid={pid}
            changeJsonFile={this.changeJsonFile}
            changeVideoFile={this.changeVideoFile}/>
        }
      </div>
    )
  }
}

export default withStyles(styles, {withTheme: true})(Content)