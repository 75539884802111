import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Home from '../components/Home'
import Header from '../components/Header'

import firebase from 'firebase/app'
import '@firebase/firestore'
import 'firebase/auth'
import 'firebase/database'

import axios from 'axios'

import Configs from '../configs/Configs'

import { createBrowserHistory } from 'history'
const history = createBrowserHistory({forceRefresh: true})

const styles = theme => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh'
  },
  loadingBar: {
    width: Configs.layout.loadingBarWidth + 'px',
    border: '1px solid #2D2D2D',
    position: 'relative',
    borderRadius: '3px'
  },
  logo: {
    marginBottom: '40px'
  },
  logoTitile: {
    marginLeft: '11.36px'
  },
  loadingProgress: {
    top: '-1.5px',
    left: '-1px',
    border: '1.5px solid #585858',
    position: 'absolute',
    borderRadius: '3px'
  }
})

class Main extends Component {

  constructor() {
    super()
    this.history = history
  }

  state={
    ref: null,
    loaded: false,
    loadingPro: 0,
    curveStyle: 'line',
    animationable: false,
    jsonUrl: '',
    jsonData: {},
    videoUrl: '',
    tSpec: null,
    pid: 0,
    sid: 0
  }

  componentDidMount () {
    let { ref, loadingPro, loaded, jsonUrl, jsonData, videoUrl, tSpec } = this.state

    let loading = setInterval(() => {
      loadingPro += 1 - loadingPro / 100
      this.setState({loadingPro})
    }, 10)

    let _this = this
    ref = firebase.database()
    let pid, sid
    if (this.history.location.search && this.history.location.search.replace(/\?/g, '').split('&').length > 1) {
      pid = parseInt(this.history.location.search.replace(/\?/g, '').split('&')[0].replace('pid=', ''))
      sid = parseInt(this.history.location.search.replace(/\?/g, '').split('&')[1].replace('sid=', ''))
    } else if (this.history.location.state && this.history.location.state.pid && this.history.location.state.sid) {
      pid = _this.history.location.state.pid
      sid = _this.history.location.state.sid
    }
    if (pid && sid) {
      this.setState({pid, sid})
      ref.ref('projectData').on('value', function(snapshot){
        let projectData = snapshot.val()
        let piid = projectData.indexOf(projectData.find(project => project && project.id === pid))
        let specData = projectData[piid].specs
        let siid = specData.indexOf(specData.find(spec => spec && spec.id === sid))
        tSpec = specData[siid]
        jsonUrl = specData[siid].json.downloadURL
        videoUrl = specData[siid].video.downloadURL
        console.log(jsonUrl)
        axios.get('https://cors-anywhere.herokuapp.com/' + jsonUrl)
          .then(res => {
            jsonData = res.data
            clearInterval(loading)
            loaded = true
            _this.setState({jsonData, jsonUrl, videoUrl, ref, tSpec, loaded})
          })
          .catch((err)=> {
            console.error(err)
            window.alert(err)
            _this.history.push({
              pathname: '/project',
              state: {
                logined: true
              }
            })
          })
      })
    } else {
      this.history.push({
        pathname: '/project',
        state: {
          logined: true
        }
      })
    }
  }

  onHandleCurveStyleChanged = (curStyle) => {
    let { curveStyle } = this.state
    curveStyle = curStyle === 'line'? 'area' : 'line'
    this.setState({curveStyle, animationable: false})
  }

  changeActiveList = (expandable) => {
    this.setState({animationable: expandable})
  }

  render() {
    const { classes } = this.props
    const { loaded, loadingPro, curveStyle, animationable, jsonData, videoUrl, pid, sid } = this.state
    return (
      <>
      {loaded?
        <>
          <Header onChangeCurveStyle={this.onHandleCurveStyleChanged} curveStyle={curveStyle} pid={pid} sid={sid}/>
          <Home jsonData={jsonData} curveStyle={curveStyle} onChangeActiveList={this.changeActiveList} animationable={animationable} videoUrl={videoUrl}/>
        </>
        :
        <div className={classes.root}>
          <div className={classes.logo}>
            <svg width="34" height="35" viewBox="0 0 34 35" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M33.7867 17.065C33.7867 26.4897 26.2233 34.13 16.8933 34.13C7.5634 34.13 0 26.4897 0 17.065C0 7.64026 7.5634 0 16.8933 0C26.2233 0 33.7867 7.64026 33.7867 17.065ZM9.40707 7.37468C8.36772 7.37468 7.52517 8.21723 7.52517 9.25658C7.52517 10.2959 8.36772 11.1385 9.40707 11.1385H20.6985C21.7378 11.1385 22.5804 10.2959 22.5804 9.25658C22.5804 8.21723 21.7378 7.37468 20.6985 7.37468H9.40707ZM13.1709 14.9023C12.1315 14.9023 11.289 15.7448 11.289 16.7842C11.289 17.8235 12.1315 18.6661 13.1709 18.6661H24.4623C25.5016 18.6661 26.3442 17.8235 26.3442 16.7842C26.3442 15.7448 25.5016 14.9023 24.4623 14.9023H13.1709ZM11.289 22.4299C10.2496 22.4299 9.40707 23.2725 9.40707 24.3118C9.40707 25.3512 10.2496 26.1937 11.289 26.1937H22.5804C23.6197 26.1937 24.4623 25.3512 24.4623 24.3118C24.4623 23.2725 23.6197 22.4299 22.5804 22.4299H11.289Z" fill="#585858"/>
            </svg>
            <svg className={classes.logoTitile} width="121" height="28" viewBox="0 0 121 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M24.4545 21.9404H19.7656V7.72252L14.1692 21.9404H10.2971L4.70071 7.90403V21.9404H0.163086V0.492569H6.39476L12.2937 14.9827L17.8901 0.492569H24.4545V21.9404Z" fill="#585858"/>
              <path d="M34.8012 18.1591C35.7491 18.1591 36.5457 17.8364 37.191 17.191C37.8566 16.5457 38.1893 15.618 38.1893 14.408C38.1893 13.1979 37.8566 12.2803 37.191 11.6551C36.5457 11.0098 35.7491 10.6871 34.8012 10.6871C33.8735 10.6871 33.0769 11.0098 32.4114 11.6551C31.7459 12.3005 31.4131 13.2181 31.4131 14.408C31.4131 15.5978 31.7459 16.5255 32.4114 17.191C33.0769 17.8364 33.8735 18.1591 34.8012 18.1591ZM34.8012 6.45199C37.0801 6.45199 38.9758 7.19817 40.4884 8.69055C42.0211 10.1829 42.7874 12.0887 42.7874 14.408C42.7874 16.7272 42.0211 18.6431 40.4884 20.1556C38.9758 21.648 37.0801 22.3942 34.8012 22.3942C32.5425 22.3942 30.6468 21.6379 29.1141 20.1254C27.5814 18.6128 26.815 16.707 26.815 14.408C26.815 12.1089 27.5814 10.2132 29.1141 8.7208C30.6468 7.20826 32.5425 6.45199 34.8012 6.45199Z" fill="#585858"/>
              <path d="M43.902 17.675L47.8044 16.9793C47.8447 17.544 48.0564 18.028 48.4396 18.4313C48.843 18.8145 49.4177 19.0061 50.1639 19.0061C50.7286 19.0061 51.1622 18.8851 51.4647 18.6431C51.7672 18.3809 51.9185 18.0683 51.9185 17.7053C51.9185 17.1003 51.4244 16.6869 50.4362 16.465L48.7119 16.0718C47.1792 15.7491 46.0498 15.1844 45.3238 14.3777C44.5978 13.5508 44.2348 12.5727 44.2348 11.4434C44.2348 10.0518 44.7793 8.87205 45.8683 7.90403C46.9775 6.936 48.359 6.45199 50.0127 6.45199C51.9689 6.45199 53.431 6.92592 54.399 7.87377C55.3872 8.80147 55.9318 9.81991 56.0326 10.9291L52.221 11.6249C52.1605 11.0804 51.9487 10.6367 51.5857 10.2938C51.2429 9.951 50.7387 9.77958 50.0732 9.77958C49.6295 9.77958 49.2463 9.90058 48.9236 10.1426C48.601 10.3846 48.4396 10.6972 48.4396 11.0804C48.4396 11.6854 48.8329 12.0585 49.6194 12.1996L51.616 12.5929C53.1285 12.8954 54.278 13.4702 55.0646 14.3172C55.8511 15.1642 56.2443 16.1625 56.2443 17.312C56.2443 18.7036 55.72 19.9035 54.6713 20.9119C53.6226 21.9001 52.1504 22.3942 50.2547 22.3942C49.1455 22.3942 48.1573 22.2328 47.2901 21.9102C46.4431 21.5875 45.7876 21.1741 45.3238 20.6699C44.8801 20.1455 44.5373 19.6313 44.2953 19.1271C44.0734 18.6229 43.9423 18.1389 43.902 17.675Z" fill="#585858"/>
              <path d="M63.2268 27.6881H58.6287V6.90575H63.0756V8.44854C63.4184 7.92419 63.9831 7.48051 64.7696 7.1175C65.5763 6.73433 66.4838 6.54274 67.4922 6.54274C69.6299 6.54274 71.324 7.27884 72.5744 8.75105C73.8449 10.2031 74.4802 12.0786 74.4802 14.3777C74.4802 16.6768 73.8046 18.5725 72.4533 20.0649C71.1223 21.5371 69.4081 22.2732 67.3107 22.2732C65.415 22.2732 64.0537 21.769 63.2268 20.7606V27.6881ZM68.9745 17.191C69.64 16.5255 69.9728 15.5978 69.9728 14.408C69.9728 13.2181 69.64 12.3005 68.9745 11.6551C68.3291 11.0098 67.5225 10.6871 66.5544 10.6871C65.6066 10.6871 64.7999 11.0098 64.1344 11.6551C63.489 12.3005 63.1663 13.2181 63.1663 14.408C63.1663 15.5978 63.489 16.5255 64.1344 17.191C64.7999 17.8364 65.6066 18.1591 66.5544 18.1591C67.5225 18.1591 68.3291 17.8364 68.9745 17.191Z" fill="#585858"/>
              <path d="M80.3207 12.6232H86.3406C86.3003 11.9375 86.028 11.3425 85.5238 10.8384C85.0398 10.3342 84.3037 10.0821 83.3155 10.0821C82.4282 10.0821 81.7223 10.3443 81.198 10.8686C80.6736 11.393 80.3812 11.9778 80.3207 12.6232ZM86.6734 16.5255L90.5152 17.6145C90.132 19.0061 89.3354 20.1556 88.1254 21.0631C86.9154 21.9505 85.4028 22.3942 83.5878 22.3942C81.4097 22.3942 79.5543 21.6682 78.0216 20.2161C76.5091 18.7641 75.7528 16.8179 75.7528 14.3777C75.7528 12.0383 76.4889 10.1325 77.9611 8.6603C79.4535 7.18809 81.2181 6.45199 83.255 6.45199C85.5944 6.45199 87.4296 7.13767 88.7607 8.50904C90.1119 9.86025 90.7875 11.7459 90.7875 14.1659C90.7875 14.9323 90.7572 15.4062 90.6967 15.5877H80.2299C80.2703 16.4146 80.6232 17.1104 81.2887 17.675C81.9542 18.2196 82.7408 18.4918 83.6483 18.4918C85.2012 18.4918 86.2095 17.8364 86.6734 16.5255Z" fill="#585858"/>
              <path d="M100.062 10.6871C99.074 10.6871 98.2471 11.0199 97.5816 11.6854C96.9363 12.3509 96.6136 13.2685 96.6136 14.4382C96.6136 15.5877 96.9464 16.4953 97.6119 17.1608C98.2976 17.8263 99.1345 18.1591 100.123 18.1591C100.95 18.1591 101.625 17.9473 102.149 17.5238C102.694 17.0801 103.057 16.5457 103.239 15.9205L107.292 17.1608C106.929 18.5927 106.112 19.8229 104.842 20.8514C103.591 21.8799 102.018 22.3942 100.123 22.3942C97.864 22.3942 95.9481 21.648 94.375 20.1556C92.8222 18.6431 92.0457 16.7373 92.0457 14.4382C92.0457 12.119 92.802 10.2132 94.3145 8.7208C95.8472 7.20826 97.7329 6.45199 99.9714 6.45199C101.928 6.45199 103.531 6.95617 104.781 7.96453C106.032 8.97289 106.838 10.2132 107.201 11.6854L103.087 12.9257C102.906 12.2803 102.563 11.7459 102.059 11.3224C101.555 10.8989 100.889 10.6871 100.062 10.6871Z" fill="#585858"/>
              <path d="M108.246 17.675L112.149 16.9793C112.189 17.544 112.401 18.028 112.784 18.4313C113.187 18.8145 113.762 19.0061 114.508 19.0061C115.073 19.0061 115.507 18.8851 115.809 18.6431C116.112 18.3809 116.263 18.0683 116.263 17.7053C116.263 17.1003 115.769 16.6869 114.781 16.465L113.056 16.0718C111.524 15.7491 110.394 15.1844 109.668 14.3777C108.942 13.5508 108.579 12.5727 108.579 11.4434C108.579 10.0518 109.124 8.87205 110.213 7.90403C111.322 6.936 112.703 6.45199 114.357 6.45199C116.313 6.45199 117.775 6.92592 118.743 7.87377C119.732 8.80147 120.276 9.81991 120.377 10.9291L116.565 11.6249C116.505 11.0804 116.293 10.6367 115.93 10.2938C115.587 9.951 115.083 9.77958 114.418 9.77958C113.974 9.77958 113.591 9.90058 113.268 10.1426C112.945 10.3846 112.784 10.6972 112.784 11.0804C112.784 11.6854 113.177 12.0585 113.964 12.1996L115.96 12.5929C117.473 12.8954 118.622 13.4702 119.409 14.3172C120.196 15.1642 120.589 16.1625 120.589 17.312C120.589 18.7036 120.064 19.9035 119.016 20.9119C117.967 21.9001 116.495 22.3942 114.599 22.3942C113.49 22.3942 112.502 22.2328 111.635 21.9102C110.788 21.5875 110.132 21.1741 109.668 20.6699C109.225 20.1455 108.882 19.6313 108.64 19.1271C108.418 18.6229 108.287 18.1389 108.246 17.675Z" fill="#585858"/>
            </svg>
          </div>
          <div className={classes.loadingBar}>
            <div className={classes.loadingProgress} style={{width: (loadingPro * Configs.layout.loadingBarWidth / 100) + 'px'}}></div>
          </div>
        </div>
      }
      </>
    )
  }
}

export default withStyles(styles, {withTheme: true})(Main)