import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import classNames from 'classnames'

import Configs from '../../configs/Configs'

import { createBrowserHistory } from 'history'
const history = createBrowserHistory({forceRefresh: true})

const styles = theme => ({
  root: {
    display: 'flex',
    height: Configs.layout.headerHeight,
    backgroundColor: Configs.theme.pallet,
    padding: theme.spacing(0, 2),
    color: Configs.theme.color
  },
  headerInner: {
    display: 'flex',
    alignItems: 'center'
  },
  logo: {
    display: 'flex',
    width: '32px',
    height: '33px'
  },
  centered: {
    justifyContent: 'center'
  },
  righted: {
    justifyContent: 'flex-end'
  },
  headerImage: {
    margin: theme.spacing(0, 1),
  },
  radioButton: {
    height: '22px',
    lineHeight: '19px',
    background: Configs.theme.pallet,
    color: '#9B9B9B',
    fontSize: '13px',
    textTransform: 'uppercase',
    cursor: 'pointer',
    padding: '0px 13px',
    paddingTop: '0.4px',
    margin: '0 5.5px'
  },
  selected: {
    background: '#0F0F0F',
    color: '#FFFFFF',
    boxSizing: 'border-box',
    borderRadius: '35px',
    lineHeight: 1.54
  },
  search: {
    position: 'relative',
    margin: '0 13px'
  },
  searchInput: {
    background: '#181818',
    width: '184px',
    borderRadius: '49px',
    fontSize: '16px',
    lineHeight: '18px',
    color: '#A6A8AB',
    // opacity: 0.5,
    padding: '8px 13px 8px 38px',
    border: 0
  },
  searchIcon: {
    position: 'absolute',
    top: '50%',
    left: '13px',
    transform: 'translateY(-50%)'
  },
  newProject : {
    background: '#FF6059',
    boxShadow: '0px 1px 0px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.2)',
    borderRadius: '4px',
    // fontFamily: 'Graphik',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '18px',
    textAlign: 'center',
    color: '#1C1414',
    cursor: 'pointer',
    margin: '0 7px',
    padding: '8px 10px',
    whiteSpace: 'nowrap',
    border: 0
  },
  onNewProject: {
    fontSize: '15px',
    lineHeight: '13px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: '#A5A5A5',
    textTransform: 'capitalize'
  },
  cancelButton: {
    marginRight: '9.4px',
    marginLeft: '18.4px'
  }
})

class Header extends Component {

  handleUpdateProject = () => {
    this.props.updateProject()
  }

  handleRadioBtn = (val) => {
    this.props.viewProjects(val)
  }

  handleSearchChange = (e) => {
    this.props.searchProject(e.target.value)
  }

  reloadPage = () => {
    history.push({
      pathname: '/'
    })
  }

  cancelProject =() => {
    this.props.cancelProject()
  }

  render() {
    const { classes, onEdit, tempProjectName, activeProjects, searchText } = this.props
    return (
      <div className={classes.root} id="menu-header">
        <Grid container spacing={0}>
          <Grid item xs={3} className={classes.headerInner}>
          <svg onClick={this.reloadPage} width="118" height="25" viewBox="0 0 118 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M49.1048 19.047H45.7897V8.99486L41.833 19.047H39.0954L35.1388 9.12319V19.047H31.9307V3.88327H36.3365L40.507 14.1278L44.4637 3.88327H49.1048V19.047Z" fill="#585858"/>
            <path d="M56.4199 16.3735C57.0901 16.3735 57.6533 16.1454 58.1095 15.6891C58.5801 15.2329 58.8153 14.577 58.8153 13.7215C58.8153 12.866 58.5801 12.2172 58.1095 11.7752C57.6533 11.319 57.0901 11.0908 56.4199 11.0908C55.764 11.0908 55.2008 11.319 54.7303 11.7752C54.2598 12.2315 54.0245 12.8802 54.0245 13.7215C54.0245 14.5627 54.2598 15.2186 54.7303 15.6891C55.2008 16.1454 55.764 16.3735 56.4199 16.3735ZM56.4199 8.09659C58.0311 8.09659 59.3714 8.62415 60.4408 9.67926C61.5244 10.7344 62.0662 12.0818 62.0662 13.7215C62.0662 15.3612 61.5244 16.7157 60.4408 17.7851C59.3714 18.8402 58.0311 19.3678 56.4199 19.3678C54.823 19.3678 53.4827 18.8331 52.3991 17.7637C51.3155 16.6943 50.7736 15.3469 50.7736 13.7215C50.7736 12.096 51.3155 10.7558 52.3991 9.70065C53.4827 8.63128 54.823 8.09659 56.4199 8.09659Z" fill="#585858"/>
            <path d="M62.8542 16.0313L65.6132 15.5394C65.6417 15.9386 65.7914 16.2808 66.0623 16.566C66.3475 16.8369 66.7538 16.9724 67.2814 16.9724C67.6806 16.9724 67.9872 16.8868 68.201 16.7157C68.4149 16.5304 68.5219 16.3094 68.5219 16.0527C68.5219 15.625 68.1725 15.3327 67.4739 15.1758L66.2548 14.8978C65.1712 14.6697 64.3727 14.2704 63.8594 13.7001C63.3461 13.1155 63.0895 12.424 63.0895 11.6255C63.0895 10.6417 63.4744 9.80759 64.2444 9.12319C65.0286 8.43879 66.0053 8.09659 67.1744 8.09659C68.5575 8.09659 69.5912 8.43166 70.2756 9.1018C70.9743 9.75768 71.3593 10.4777 71.4305 11.2619L68.7357 11.7538C68.693 11.3689 68.5432 11.0552 68.2866 10.8128C68.0442 10.5704 67.6877 10.4492 67.2172 10.4492C66.9035 10.4492 66.6326 10.5348 66.4045 10.7059C66.1764 10.877 66.0623 11.098 66.0623 11.3689C66.0623 11.7966 66.3403 12.0604 66.8964 12.1602L68.308 12.4382C69.3774 12.6521 70.1901 13.0585 70.7461 13.6573C71.3022 14.2562 71.5803 14.962 71.5803 15.7747C71.5803 16.7585 71.2095 17.6069 70.4681 18.3198C69.7267 19.0184 68.6858 19.3678 67.3456 19.3678C66.5613 19.3678 65.8627 19.2537 65.2496 19.0256C64.6507 18.7974 64.1873 18.5051 63.8594 18.1487C63.5457 17.778 63.3033 17.4144 63.1322 17.0579C62.9754 16.7015 62.8827 16.3593 62.8542 16.0313Z" fill="#585858"/>
            <path d="M76.5169 23.1106H73.266V8.41741H76.41V9.50816C76.6524 9.13745 77.0516 8.82377 77.6077 8.56712C78.178 8.29621 78.8196 8.16076 79.5325 8.16076C81.0439 8.16076 82.2416 8.68118 83.1256 9.72204C84.0239 10.7486 84.473 12.0747 84.473 13.7001C84.473 15.3255 83.9954 16.6658 83.0401 17.7209C82.099 18.7618 80.8871 19.2822 79.4042 19.2822C78.0639 19.2822 77.1015 18.9258 76.5169 18.2128V23.1106ZM80.5805 15.6891C81.051 15.2186 81.2863 14.5627 81.2863 13.7215C81.2863 12.8802 81.051 12.2315 80.5805 11.7752C80.1242 11.319 79.5539 11.0908 78.8695 11.0908C78.1994 11.0908 77.6291 11.319 77.1585 11.7752C76.7023 12.2315 76.4741 12.8802 76.4741 13.7215C76.4741 14.5627 76.7023 15.2186 77.1585 15.6891C77.6291 16.1454 78.1994 16.3735 78.8695 16.3735C79.5539 16.3735 80.1242 16.1454 80.5805 15.6891Z" fill="#585858"/>
            <path d="M88.6023 12.4596H92.8584C92.8299 11.9748 92.6374 11.5542 92.2809 11.1978C91.9387 10.8413 91.4183 10.6631 90.7197 10.6631C90.0923 10.6631 89.5933 10.8484 89.2225 11.2192C88.8518 11.5899 88.6451 12.0034 88.6023 12.4596ZM93.0937 15.2186L95.8099 15.9886C95.539 16.9724 94.9757 17.7851 94.1203 18.4267C93.2648 19.0541 92.1954 19.3678 90.9121 19.3678C89.3722 19.3678 88.0605 18.8545 86.9769 17.8279C85.9075 16.8013 85.3728 15.4254 85.3728 13.7001C85.3728 12.0461 85.8932 10.6987 86.9341 9.65788C87.9892 8.61702 89.2368 8.09659 90.6769 8.09659C92.3308 8.09659 93.6283 8.58137 94.5694 9.55094C95.5247 10.5062 96.0023 11.8394 96.0023 13.5504C96.0023 14.0922 95.981 14.4273 95.9382 14.5556H88.5381C88.5667 15.1402 88.8162 15.6321 89.2867 16.0313C89.7572 16.4163 90.3133 16.6088 90.9549 16.6088C92.0528 16.6088 92.7657 16.1454 93.0937 15.2186Z" fill="#585858"/>
            <path d="M102.56 11.0908C101.861 11.0908 101.276 11.3261 100.806 11.7966C100.35 12.2671 100.121 12.9159 100.121 13.7429C100.121 14.5556 100.357 15.1972 100.827 15.6677C101.312 16.1383 101.904 16.3735 102.602 16.3735C103.187 16.3735 103.665 16.2238 104.035 15.9244C104.42 15.6107 104.677 15.2329 104.805 14.7909L107.671 15.6677C107.415 16.6801 106.837 17.5498 105.939 18.277C105.055 19.0042 103.943 19.3678 102.602 19.3678C101.005 19.3678 99.6509 18.8402 98.5388 17.7851C97.4409 16.7157 96.8919 15.3683 96.8919 13.7429C96.8919 12.1032 97.4266 10.7558 98.496 9.70065C99.5796 8.63128 100.913 8.09659 102.495 8.09659C103.878 8.09659 105.012 8.45305 105.896 9.16596C106.78 9.87888 107.35 10.7558 107.607 11.7966L104.698 12.6735C104.57 12.2172 104.328 11.8394 103.971 11.54C103.615 11.2405 103.144 11.0908 102.56 11.0908Z" fill="#585858"/>
            <path d="M108.346 16.0313L111.105 15.5394C111.133 15.9386 111.283 16.2808 111.554 16.566C111.839 16.8369 112.246 16.9724 112.773 16.9724C113.172 16.9724 113.479 16.8868 113.693 16.7157C113.907 16.5304 114.014 16.3094 114.014 16.0527C114.014 15.625 113.664 15.3327 112.966 15.1758L111.746 14.8978C110.663 14.6697 109.864 14.2704 109.351 13.7001C108.838 13.1155 108.581 12.424 108.581 11.6255C108.581 10.6417 108.966 9.80759 109.736 9.12319C110.52 8.43879 111.497 8.09659 112.666 8.09659C114.049 8.09659 115.083 8.43166 115.767 9.1018C116.466 9.75768 116.851 10.4777 116.922 11.2619L114.227 11.7538C114.185 11.3689 114.035 11.0552 113.778 10.8128C113.536 10.5704 113.179 10.4492 112.709 10.4492C112.395 10.4492 112.124 10.5348 111.896 10.7059C111.668 10.877 111.554 11.098 111.554 11.3689C111.554 11.7966 111.832 12.0604 112.388 12.1602L113.8 12.4382C114.869 12.6521 115.682 13.0585 116.238 13.6573C116.794 14.2562 117.072 14.962 117.072 15.7747C117.072 16.7585 116.701 17.6069 115.96 18.3198C115.218 19.0184 114.178 19.3678 112.837 19.3678C112.053 19.3678 111.354 19.2537 110.741 19.0256C110.142 18.7974 109.679 18.5051 109.351 18.1487C109.037 17.778 108.795 17.4144 108.624 17.0579C108.467 16.7015 108.374 16.3593 108.346 16.0313Z" fill="#585858"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M23.8872 12.065C23.8872 18.7283 18.5399 24.13 11.9436 24.13C5.34734 24.13 0 18.7283 0 12.065C0 5.40168 5.34734 0 11.9436 0C18.5399 0 23.8872 5.40168 23.8872 12.065ZM6.65103 5.21392C5.91621 5.21392 5.32052 5.80961 5.32052 6.54443C5.32052 7.27925 5.91621 7.87494 6.65103 7.87494H14.6341C15.3689 7.87494 15.9646 7.27925 15.9646 6.54443C15.9646 5.80961 15.3689 5.21392 14.6341 5.21392H6.65103ZM9.31205 10.536C8.57723 10.536 7.98154 11.1316 7.98154 11.8665C7.98154 12.6013 8.57723 13.197 9.31205 13.197H17.2951C18.0299 13.197 18.6256 12.6013 18.6256 11.8665C18.6256 11.1316 18.0299 10.536 17.2951 10.536H9.31205ZM7.98154 15.858C7.24672 15.858 6.65103 16.4537 6.65103 17.1885C6.65103 17.9233 7.24672 18.519 7.98154 18.519H15.9646C16.6994 18.519 17.2951 17.9233 17.2951 17.1885C17.2951 16.4537 16.6994 15.858 15.9646 15.858H7.98154Z" fill="#585858"/>
          </svg>          
          </Grid>
          {
            onEdit === -1?
              <Grid item xs={6} className={classNames(classes.headerInner, classes.centered)} >
                <label className={activeProjects==='favorite' ? classNames(classes.radioButton, classes.selected) : classes.radioButton} onClick={() => this.handleRadioBtn('favorite')}>favorites</label>
                <label className={activeProjects==='active' ? classNames(classes.radioButton, classes.selected) : classes.radioButton} onClick={() => this.handleRadioBtn('active')}>active</label>
                <label className={activeProjects==='archived' ? classNames(classes.radioButton, classes.selected) : classes.radioButton} onClick={() => this.handleRadioBtn('archived')}>archived</label>
              </Grid>
              :
              <Grid item xs={6} className={classNames(classes.headerInner, classes.centered)} >
                <label className={classes.onNewProject}>{tempProjectName? tempProjectName: 'Project Name'}</label>
              </Grid>
          }
          <Grid item xs={3} className={classNames(classes.headerInner, classes.righted)}>
            {
              onEdit === -1?
              <div className={classes.search}>
                <input className={classes.searchInput} placeholder="Search" onChange={(e) => this.handleSearchChange(e)} value={searchText} />
                <svg className={classes.searchIcon} width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.7686 12.7084L12.9561 10.8916C13.9027 9.43647 14.279 7.68322 14.013 5.96784C13.7469 4.25245 12.8572 2.69558 11.5142 1.59561C10.1713 0.495645 8.46972 -0.0700509 6.73553 0.00694102C5.00134 0.083933 3.35656 0.798197 2.1164 2.01285C0.87625 3.22749 0.127967 4.85708 0.01496 6.5893C-0.0980467 8.32152 0.432173 10.0345 1.504 11.4C2.57583 12.7655 4.11388 13.6874 5.82337 13.989C7.53286 14.2907 9.29354 13.9509 10.768 13.0347L12.5849 15.8472C12.7643 16.1239 13 16.3596 13.2767 16.5391C13.7907 16.8695 14.4148 16.9822 15.0119 16.8525C15.609 16.7227 16.13 16.3611 16.4605 15.8472C16.7909 15.3332 16.9037 14.7091 16.7739 14.112C16.6442 13.5149 16.2825 12.9939 15.7686 12.6634V12.7084ZM7.00486 11.9097C6.05922 11.9097 5.13481 11.6293 4.34854 11.1039C3.56226 10.5785 2.94944 9.8318 2.58756 8.95814C2.22568 8.08448 2.13099 7.12313 2.31548 6.19566C2.49996 5.26818 2.95533 4.41625 3.624 3.74758C4.29267 3.07891 5.14461 2.62354 6.07208 2.43905C6.99955 2.25457 7.9609 2.34925 8.83456 2.71113C9.70822 3.07301 10.455 3.68584 10.9803 4.47211C11.5057 5.25838 11.7861 6.18279 11.7861 7.12843C11.7861 7.75631 11.6624 8.37805 11.4222 8.95814C11.1819 9.53822 10.8297 10.0653 10.3857 10.5093C9.94173 10.9533 9.41465 11.3054 8.83456 11.5457C8.25447 11.786 7.63274 11.9097 7.00486 11.9097Z" fill="#666768"/>
                </svg>
              </div>:
              <></>
            }
            <button className={classes.newProject} id="btn-project" onClick={this.handleUpdateProject} disabled={onEdit !== -1 && !tempProjectName}>{onEdit === -1? 'New Project':'Save Project'}</button>
            <svg onClick={this.cancelProject} className={classes.cancelButton} style={{display: onEdit === -1? 'none':'block'}} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17.6019 2.04666L15.953 0.397766L8.99968 7.3511L2.04635 0.397766L0.397461 2.04666L7.35079 8.99999L0.397461 15.9533L2.04635 17.6022L8.99968 10.6489L15.953 17.6022L17.6019 15.9533L10.6486 8.99999L17.6019 2.04666Z" fill="#B8B8B8"/>
            </svg>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default withStyles(styles, {withTheme: true})(Header)