import React, { Component } from 'react'
import { withMediaProps } from 'react-media-player'
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  mediaControls: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: 'calc( 100% - 30px)',
    height: '48px',
    background: '#252525',
    boxShadow: '0px 1px 0px #000000, inset 0px 1px 0px rgba(255, 255, 255, 0.06)',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 15px'
  },
  curVideoTime: {
    fontSize: '12px',
    lineHeight: '12px',
    color: '#8E8A84',
    width: '40px'
  }
})

class CustomPlayPause extends Component {

  state = {
    loop: false
  }

  UNSAFE_componentWillUpdate({ media }) {
    return this.props.media.isPlaying !== media.isPlaying
  }
 
  _handlePlayPause = () => {
    this.props.media.playPause()
    this.props.setLoop(false)
  }

  _handleReplay = () => {
    this.props.media.stop()
    this.props.media.play()
    this.props.setLoop(true)
  }
 
  render() {
    const { classes, media } = this.props
    
    return (
      <div className={classes.mediaControls}>
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={this._handleReplay}>
          <path d="M20.71 4.29L17.71 1.29C17.6168 1.19676 17.5061 1.1228 17.3842 1.07234C17.2624 1.02188 17.1319 0.995911 17 0.995911C16.7337 0.995911 16.4783 1.1017 16.29 1.29C16.1017 1.47831 15.9959 1.7337 15.9959 2C15.9959 2.2663 16.1017 2.5217 16.29 2.71L17.59 4H4C3.20435 4 2.44129 4.31607 1.87868 4.87868C1.31607 5.44129 1 6.20435 1 7V10C1 10.2652 1.10536 10.5196 1.29289 10.7071C1.48043 10.8946 1.73478 11 2 11C2.26522 11 2.51957 10.8946 2.70711 10.7071C2.89464 10.5196 3 10.2652 3 10V7C3 6.73479 3.10536 6.48043 3.29289 6.2929C3.48043 6.10536 3.73478 6 4 6H17.59L16.29 7.29C16.1963 7.38297 16.1219 7.49357 16.0711 7.61543C16.0203 7.73728 15.9942 7.86799 15.9942 8C15.9942 8.13201 16.0203 8.26272 16.0711 8.38458C16.1219 8.50644 16.1963 8.61704 16.29 8.71C16.383 8.80373 16.4936 8.87812 16.6154 8.92889C16.7373 8.97966 16.868 9.0058 17 9.0058C17.132 9.0058 17.2627 8.97966 17.3846 8.92889C17.5064 8.87812 17.617 8.80373 17.71 8.71L20.71 5.71C20.8037 5.61704 20.8781 5.50644 20.9289 5.38458C20.9797 5.26272 21.0058 5.13201 21.0058 5C21.0058 4.86799 20.9797 4.73728 20.9289 4.61543C20.8781 4.49357 20.8037 4.38297 20.71 4.29Z" fill="#929191"/>
          <path d="M19.9999 11C19.7347 11 19.4804 11.1054 19.2928 11.2929C19.1053 11.4804 18.9999 11.7348 18.9999 12V15C18.9999 15.2652 18.8946 15.5196 18.707 15.7071C18.5195 15.8946 18.2652 16 17.9999 16H4.40994L5.70994 14.71C5.89824 14.5217 6.00403 14.2663 6.00403 14C6.00403 13.7337 5.89824 13.4783 5.70994 13.29C5.52164 13.1017 5.26624 12.9959 4.99994 12.9959C4.73364 12.9959 4.47824 13.1017 4.28994 13.29L1.28994 16.29C1.19621 16.383 1.12182 16.4936 1.07105 16.6154C1.02028 16.7373 0.994141 16.868 0.994141 17C0.994141 17.132 1.02028 17.2627 1.07105 17.3846C1.12182 17.5064 1.19621 17.617 1.28994 17.71L4.28994 20.71C4.3829 20.8037 4.4935 20.8781 4.61536 20.9289C4.73722 20.9797 4.86793 21.0058 4.99994 21.0058C5.13195 21.0058 5.26266 20.9797 5.38452 20.9289C5.50637 20.8781 5.61698 20.8037 5.70994 20.71C5.80367 20.617 5.87806 20.5064 5.92883 20.3846C5.9796 20.2627 6.00574 20.132 6.00574 20C6.00574 19.868 5.9796 19.7373 5.92883 19.6154C5.87806 19.4936 5.80367 19.383 5.70994 19.29L4.40994 18H17.9999C18.7956 18 19.5587 17.6839 20.1213 17.1213C20.6839 16.5587 20.9999 15.7956 20.9999 15V12C20.9999 11.7348 20.8946 11.4804 20.707 11.2929C20.5195 11.1054 20.2652 11 19.9999 11Z" fill="#929191"/>
        </svg>
        {!media.isPlaying? <div id="playpause" onClick={this._handlePlayPause}><svg width="20" height="25" viewBox="0 0 20 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.53687 0.978011C0.871149 0.554368 0 1.03258 0 1.82167V23.845C0 24.6341 0.87115 25.1123 1.53688 24.6887L18.8409 13.677C19.4584 13.284 19.4584 12.3826 18.8409 11.9897L1.53687 0.978011Z" fill="#929191"/>
          </svg></div> : <div id="playpause" onClick={this._handlePlayPause}><svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V18C0 18.5304 0.210714 19.0391 0.585786 19.4142C0.960859 19.7893 1.46957 20 2 20H7C7.53043 20 8.03914 19.7893 8.41421 19.4142C8.78929 19.0391 9 18.5304 9 18V2C9 1.46957 8.78929 0.960859 8.41421 0.585786C8.03914 0.210714 7.53043 0 7 0ZM20 0H15C14.4696 0 13.9609 0.210714 13.5858 0.585786C13.2107 0.960859 13 1.46957 13 2V18C13 18.5304 13.2107 19.0391 13.5858 19.4142C13.9609 19.7893 14.4696 20 15 20H20C20.5304 20 21.0391 19.7893 21.4142 19.4142C21.7893 19.0391 22 18.5304 22 18V2C22 1.46957 21.7893 0.960859 21.4142 0.585786C21.0391 0.210714 20.5304 0 20 0Z" fill="#929191"/>
          </svg></div>
        }
        <label className={classes.curVideoTime}>{Math.round(media.currentTime * 1000)}ms</label>
      </div>
    )
  }
}

export default withStyles(styles, {withTheme: true})(withMediaProps(CustomPlayPause))