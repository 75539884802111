import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import classNames from 'classnames'

import Configs from '../../configs/Configs'

import { createBrowserHistory } from 'history'
const history = createBrowserHistory({forceRefresh: true})

const styles = theme => ({
  root: {
    display: 'flex',
    flexFlow: 'wrap',
    minHeight: 'calc(100vh - '+Configs.layout.headerHeight+'px)',
    padding: '31px',
    color: Configs.theme.color,
    width: 'calc(100% - ' + Configs.layout.menuSidebarWidth + 'px)',
    alignContent: 'flex-start'
  },
  project: {
    background: '#1E1E1E',
    border: '1px solid #3C3C3C',
    boxSizing: 'border-box',
    boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.25)',
    borderRadius: '4px',
    width: '295px',
    height: '115px',
    padding: '14px 12.25px 18px 16px',
    margin: '17px',
    display: 'flex',
    flexFlow: 'column',
    alignContent: 'stretch'
  },
  projectDetail: {
    display: 'flex',
    justifyContent: 'space-between',
    // fontFamily: 'Graphik',
    fontStyle: 'normal',
    fontWeight: 'normal',
    alignItems: 'start'
  },
  projectNames: {
    flexGrow: 1,
    marginLeft: '7.3px',
    marginBottom: '26px'
  },
  projectName: {
    fontWeight: '500',
    fontSize: '15px',
    lineHeight: '17px',
    display: 'flex',
    alignItems: 'center',
    color: '#DDDDDD',
    margin: 0,
    marginBottom: '7px',
    textTransform: 'capitalize'
  },
  projectSpecCount: {
    fontSize: '14px',
    lineHeight: '15px',
    color: '#9B9B9B',
    margin: 0
  },
  projectUpdated: {
    fontSize: '14px',
    lineHeight: '15px',
    color: '#464645',
    margin: 0
  },
  popoverList: {
    padding: '0 9px 0 20px',
    borderTop: '2px solid #504F4C',
    cursor: 'pointer'
  },
  popoverIcon: {
    cursor: 'pointer'
  }
})

class Contentmenu extends Component {

  state = {
    anchorEl: null,
    currentProjectID: 0,
    starHovered: -1
  }

  handleClick = (event, pid) => {
    this.setState({currentProjectID: pid, anchorEl: event.currentTarget});
  };

  handleClose = () => {
    this.setState({anchorEl: null});
  }

  handleUpdateProject = (pid) => {
    this.setState({anchorEl: null})
    this.props.updateProject(pid)
  }

  handleArchiveProject = (pid) => {
    this.setState({anchorEl: null})
    this.props.archiveProject(pid)
  }

  handleDeleteProject = (pid) => {
    this.setState({anchorEl: null})
    if (window.confirm("Are you sure to delete this project?")) this.props.deleteProject(pid)
  }

  handleFavoriteProject = (pid, direct) => {
    this.setState({anchorEl: null})
    this.props.favoriteProject(pid)
  }

  handleAddSpec = (pid) => {
    this.props.addSpec(pid)
  }

  goSpecList = (pid, e) => {
    if (pid && !this.state.anchorEl && !['path', 'svg'].includes(e.target.tagName)) {
      history.push({
        pathname: '/spec',
        state: {
          pid: pid,
          newSpec: false
        }
      })
    }
  }

  hoverStar = (index) => {
    if (this.state.starHovered === index) this.setState({starHovered: -1})
    else this.setState({starHovered: index})
  }
  
  render() {
    const { anchorEl, currentProjectID, starHovered } = this.state
    const open = Boolean(anchorEl);
    const id = 'project-popover';
    const { classes, projectData, activeProjects, searchText } = this.props

    return (
      <div className={classes.root}>
        {
          projectData.map((project, index) => {
            if (((activeProjects === 'active' && !project.archived) || (activeProjects === 'favorite' && project.favorite) || (activeProjects === 'archived' && project.archived)) && (searchText === '' || (searchText !== '' && project.name.toLowerCase().includes(searchText.toLowerCase())))) {
              return (<div className={classNames(classes.project, 'product')} key={index} onClick={(e) => this.goSpecList(project.id, e)}>
                <div className={classes.projectDetail}>
                  <svg width="3" height="43" viewBox="0 0 3 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="2.70181" height="42.1189" rx="1.3509" fill={project.color}/>
                  </svg>
                  <div className={classes.projectNames}>
                    <p className={classes.projectName}>{project.name}</p>
                    <p className={classes.projectSpecCount}>{project.specs && project.specs.length? project.specs.length + ' Specs':'Empty Project'}</p>
                  </div>
                  <svg className={classes.popoverIcon} onClick={(e) => this.handleClick(e, project.id)} width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.375 2.625H12.625C12.9234 2.625 13.2095 2.50647 13.4205 2.29549C13.6315 2.08452 13.75 1.79837 13.75 1.5C13.75 1.20163 13.6315 0.915483 13.4205 0.704505C13.2095 0.493526 12.9234 0.375 12.625 0.375H1.375C1.07663 0.375 0.790483 0.493526 0.579505 0.704505C0.368526 0.915483 0.25 1.20163 0.25 1.5C0.25 1.79837 0.368526 2.08452 0.579505 2.29549C0.790483 2.50647 1.07663 2.625 1.375 2.625ZM12.625 4.875H1.375C1.07663 4.875 0.790483 4.99353 0.579505 5.2045C0.368526 5.41548 0.25 5.70163 0.25 6C0.25 6.29837 0.368526 6.58452 0.579505 6.7955C0.790483 7.00647 1.07663 7.125 1.375 7.125H12.625C12.9234 7.125 13.2095 7.00647 13.4205 6.7955C13.6315 6.58452 13.75 6.29837 13.75 6C13.75 5.70163 13.6315 5.41548 13.4205 5.2045C13.2095 4.99353 12.9234 4.875 12.625 4.875ZM12.625 9.375H1.375C1.07663 9.375 0.790483 9.49353 0.579505 9.7045C0.368526 9.91548 0.25 10.2016 0.25 10.5C0.25 10.7984 0.368526 11.0845 0.579505 11.2955C0.790483 11.5065 1.07663 11.625 1.375 11.625H12.625C12.9234 11.625 13.2095 11.5065 13.4205 11.2955C13.6315 11.0845 13.75 10.7984 13.75 10.5C13.75 10.2016 13.6315 9.91548 13.4205 9.7045C13.2095 9.49353 12.9234 9.375 12.625 9.375Z" fill="#9B9B9B"/>
                  </svg>
                </div>
                <div className={classes.projectDetail}>
                  <p className={classes.projectUpdated}>Updated {project.lastUpdated}</p>
                  <svg onMouseOver={() => this.hoverStar(index)} onMouseOut={() => this.hoverStar(index)} onClick={() => this.handleFavoriteProject(project.id, true)} width="18" height="18" viewBox="0 0 18 18" fill={starHovered === index || project.favorite? "#DFA023":"none"} xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.482 5.94751L11.5983 6.18328L11.8585 6.22106L16.5832 6.90726C16.5833 6.90728 16.5834 6.9073 16.5835 6.90731C16.6592 6.91842 16.7303 6.95042 16.7888 6.99973L17.111 6.61739L16.7888 6.99973C16.8474 7.0491 16.891 7.11386 16.9148 7.18671C16.9385 7.25955 16.9415 7.33758 16.9232 7.41201C16.905 7.48638 16.8664 7.5542 16.8117 7.60781C16.8117 7.60785 16.8117 7.60788 16.8116 7.60791L13.3925 10.9427L13.2043 11.1263L13.2489 11.3854L14.0589 16.0935L14.0589 16.0939C14.072 16.1696 14.0636 16.2474 14.0348 16.3186L14.4981 16.5065L14.0348 16.3186C14.0059 16.3897 13.9577 16.4514 13.8957 16.4965C13.8336 16.5417 13.7601 16.5686 13.6835 16.5742C13.6072 16.5797 13.531 16.5639 13.4632 16.5285C13.4629 16.5283 13.4626 16.5282 13.4624 16.528L9.23455 14.2792L8.99974 14.1543L8.76493 14.2792L4.53712 16.528C4.53683 16.5282 4.53654 16.5283 4.53624 16.5285C4.4685 16.5639 4.39222 16.5797 4.31598 16.5742C4.2394 16.5686 4.16591 16.5417 4.10383 16.4965C4.04174 16.4514 3.99355 16.3897 3.96469 16.3186C3.93584 16.2474 3.92748 16.1696 3.94056 16.0939L3.94063 16.0935L4.75063 11.3854L4.79521 11.1263L4.60698 10.9427L1.18785 7.60791C1.18773 7.6078 1.18761 7.60768 1.18749 7.60756C1.13296 7.55399 1.09443 7.48627 1.07625 7.41201C1.05803 7.33759 1.06096 7.25955 1.08472 7.18671C1.10847 7.11386 1.1521 7.0491 1.21069 6.99973C1.26922 6.95041 1.34035 6.9184 1.41607 6.9073C1.41615 6.90729 1.41624 6.90728 1.41632 6.90726L6.14098 6.22106L6.40116 6.18328L6.5175 5.94751L8.63165 1.66298C8.63177 1.66274 8.63189 1.6625 8.63201 1.66226C8.66612 1.59412 8.71849 1.5368 8.78329 1.49669C8.84831 1.45644 8.92327 1.43512 8.99974 1.43512C9.07622 1.43512 9.15117 1.45644 9.2162 1.49669L9.47936 1.07155L9.2162 1.49669C9.28102 1.53682 9.33341 1.59417 9.36752 1.66234C9.36763 1.66255 9.36773 1.66276 9.36784 1.66298L11.482 5.94751Z" stroke={starHovered === index || project.favorite? "#DFA023":"#464645"}/>
                  </svg>
                </div>
              </div>)
            }
            return <div key={index}></div>
          })
        }
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <div className={classes.popoverList} onClick={() => this.handleAddSpec(currentProjectID)}>Add New Spec</div>
          <div className={classes.popoverList} onClick={() => this.handleUpdateProject(currentProjectID)}>Edit...</div>
          <div className={classes.popoverList} onClick={() => this.handleArchiveProject(currentProjectID)}>
            {projectData.find(project => project && project.id === currentProjectID) && projectData.find(project => project && project.id === currentProjectID).archived? 'Active' : 'Archive'}
          </div>
          <div className={classes.popoverList} onClick={() => this.handleDeleteProject(currentProjectID)}>Delete...</div>
          <div className={classes.popoverList} onClick={() => this.handleFavoriteProject(currentProjectID)}>
            {projectData.find(project => project && project.id === currentProjectID) && projectData.find(project => project && project.id === currentProjectID).favorite? 'Unmark As Favorite' : 'Mark As Favorite'}
          </div>
        </Popover>
      </div>
    )
  }
}

export default withStyles(styles, {withTheme: true})(Contentmenu)