import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import FileDrop from 'react-file-drop';
import Configs from '../../configs/Configs'
import classNames from 'classnames'

import firebase from 'firebase/app'
import '@firebase/firestore'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'

const styles = theme => ({
  root: {
    display: 'flex',
    flexFlow: 'wrap',
    minHeight: 'calc(100vh - ' + Configs.layout.headerHeight + 'px)',
    padding: '31px',
    color: Configs.theme.color,
    width: '100%'
  },
  inputSpec: {
    height: 'inherit',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexFlow: 'column'
  },
  inputSpecName: {
    width: '712px',
    background: 'transparent',
    border: 0,
    borderBottom: '1px solid #323232',
    fontSize: '22px',
    lineHeight: '25px',
    color: '#A6A8AB',
    padding: '14px 10px',
    textTransform: 'capitalize'
  },
  inputSpecDetail: {
    width: '732px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  fileSpec: {
    marginTop: '77px',
    background: '#161616',
    border: '2px dashed #272727',
    boxSizing: 'border-box',
    borderRadius: '8px',
    width: '345px',
    height: '335px',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  onreadyFileDrop: {
    background: 'rgba(68, 120, 87, 0.55)',
    border: '1px solid rgba(101, 161, 86, 0.55)'
  },
  fileSpecText: {
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '34px',
    textAlign: 'center',
    color: '#A8A8A8',
    marginTop: '40.17px'
  },
  fileSpecFormat: {
    fontSize: '15px',
    lineHeight: '122%',
    textAlign: 'center',
    color: '#656565',
    marginTop: '32px'
  },
  dataError: {
    background: '#FF6059',
    boxShadow: '0px 1px 0px #000000, inset 0px 1px 0px rgba(255, 255, 255, 0.06)',
    borderRadius: '4px',
    width: '732px',
    padding: '8px',
    position: 'fixed',
    top: '102px',
    left: '50%',
    transform: 'translateX(-50%)',
    color: '#1C1414'
  },
  dataTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '15px',
    textAlign: 'left',
    borderBottom: '1px solid #000000',
    paddingBottom: '9px'
  },
  dataContent: {
    marginTop: '12px',
    marginBottom: '7px',
    fontSize: '15px',
    lineHeight: '17px',
    display: 'flex',
    flexFlow: 'column',
    height: 'auto',
    transition: 'height 1s linear'
  }
})

class UpdateProject extends Component {

  state = {
    jsonFile: [],
    videoFile: [],
    onDropReady: [false, false],
    onUpload: [-1, -1],
    errorDetailShow: false,
    errorShow: false,
    errorContent: [],
    errorTitle: ''
  }

  componentDidMount () {
    let { jsonFile, videoFile } = this.state
    const { tempJson, tempVideo, onEdit, specData } = this.props
    if (onEdit === 0) {
      if (tempJson.name) jsonFile = [tempJson]
      if (tempVideo.name) videoFile = [tempVideo]
    } else {
      let dspec = specData.find(spec => spec && spec.id === onEdit)
      jsonFile = [dspec.json]
      videoFile = [dspec.video]
      this.props.changeJsonFile(dspec.json)
      this.props.changeVideoFile(dspec.video)
    }
    this.setState({jsonFile, videoFile})
  }

  handleSpecNameChange = (e) => {
    this.props.handleSpecNameChange(e.target.value)
  }

  preLoadJson (file, callback) {
    let reader = new FileReader()
    reader.readAsText(file, 'UTF-8')
    reader.onload = function(evt) {
      callback(evt.target.result)
    }
  }
  
  handleDropJson = (files, _this) => {
    let { pid, onEdit, specData } = _this.props
    let { onUpload } = _this.state
    let errs = []
    // console.log(files)
    _this.setState({onDropReady: [false, false]})
    if (files[0].type === "application/json") {
      _this.preLoadJson(files[0], (data) => {
        // console.log(data)
        try {
          let json = JSON.parse(data)
          let keys = Object.keys(json)
          if (keys.includes('firstKeyTime') &&
              keys.includes('lastKeyTime') &&
              keys.includes('layers') &&
              Array.isArray(json.layers) &&
              Array.isArray(json.layers[0].props) &&
              Number(json.firstKeyTime) === json.firstKeyTime &&
              Number(json.lastKeyTime) === json.lastKeyTime &&
              Number(json.layers[0].props[0].duration) === json.layers[0].props[0].duration &&
              Number(json.layers[0].props[0].startTime) === json.layers[0].props[0].startTime &&
              Number(json.layers[0].props[0].endTime) === json.layers[0].props[0].endTime) {
            _this.setState({errorTitle: '', errorContent: [], errorShow: false})
            _this.setState({jsonFile: files})
            let newId = onEdit
            if (onEdit === 0) {
              let idArray = specData.map(spec => spec.id)
              newId = idArray.length === 0? 1 : Math.max.apply(null, idArray) * 1 + 1
            }
            const uploadTask = firebase.storage().ref().child('json/' + pid + '/' + newId + '/' + files[0].name).put(files[0])
            uploadTask.on('state_changed', function(snapshot){
              onUpload[0] = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              _this.setState({onUpload})
              console.log('Upload is ' + onUpload[0] + '% done')
              switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED:
                  console.log('Upload is paused')
                  break
                case firebase.storage.TaskState.RUNNING:
                  console.log('Upload is running')
                  break
                default:
                  break
              }
            }, function(error) {
              console.log('Upload failed')
            }, function() {
              onUpload[0] = 100
              _this.setState({onUpload})
              uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                console.log('File available at', downloadURL)
                _this.props.changeJsonFile({
                  downloadURL: downloadURL,
                  name: files[0].name,
                  path: 'json/' + pid + '/' + newId + '/' + files[0].name
                })
              })
            })            
          } else {
            errs= ['keyName / nodeName is missing', 'key/Node does not have correct value']
            _this.setState({errorTitle: 'JSON is not in correct format.', errorContent: errs, errorShow: true})
          }
        } catch (err) {
          errs = ['Can not parse Json data']
          _this.setState({errorTitle: 'JSON is not in correct format.', errorContent: errs, errorShow: true})
        }
      })
    } else {
      errs = ['Can not parse Json data']
      _this.setState({errorTitle: 'Must be Json file.', errorContent: errs, errorShow: true})
    }
  }
  
  handleDropVideo = (files, _this) => {
    let { pid, onEdit, specData } = _this.props
    let { onUpload } = _this.state
    _this.setState({onDropReady: [false, false]})
    if (files[0].type === "video/mp4") {
      _this.setState({videoFile: files})
      let newId = onEdit
      if (onEdit === 0) {
        let idArray = specData.map(spec => spec.id)
        newId = idArray.length === 0? 1 : Math.max.apply(null, idArray) * 1 + 1
      }
      const uploadTask = firebase.storage().ref().child('json/' + pid + '/' + newId + '/' + files[0].name).put(files[0])
      uploadTask.on('state_changed', function(snapshot){
        onUpload[1] = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        _this.setState({onUpload})
        console.log('Upload is ' + onUpload[1] + '% done')
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED:
            console.log('Upload is paused')
            break
          case firebase.storage.TaskState.RUNNING:
            console.log('Upload is running')
            break
          default:
            break
        }
      }, function(error) {
        console.log('Upload failed')
      }, function() {
        onUpload[1] = 100
        _this.setState({onUpload})
        uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
          console.log('File available at', downloadURL)
          _this.props.changeVideoFile({
            downloadURL: downloadURL,
            name: files[0].name,
            path: 'video/' + pid + '/' + newId + '/' + files[0].name
          })
        })
      })
    }
  }

  onDragOver = (target) => {
    if (target === 'json') this.setState({onDropReady: [true, false]})
    else this.setState({onDropReady: [false, true]})
  }

  onDragLeave = () => {
    this.setState({onDropReady: [false, false]})
  }

  hideError = () => {
    this.setState({errorDetailShow: !this.state.errorDetailShow})
  }

  closeError = () => {
    this.setState({errorShow: !this.state.errorShow, errorContent: [], errorTitle: ''})
  }
  
  render() {
    const { jsonFile, videoFile, onDropReady, onUpload, errorShow, errorDetailShow, errorContent, errorTitle } = this.state
    const { classes, tempSpecName } = this.props // , onEdit, specData
    const __this = this

    return (
      <div className={classes.root}>
        <div className={classes.inputSpec}>
          <input className={classes.inputSpecName} id="input-project-name" type="text" placeholder="Spec Name" onChange={(e) => this.handleSpecNameChange(e)} value={tempSpecName}/>
          <div className={classes.inputSpecDetail}>
            <FileDrop onDrop={(e) => __this.handleDropJson(e, __this)} onDragOver={() => __this.onDragOver('json')} onDragLeave={__this.onDragLeave}>
              <div className={onDropReady[0]? classNames(classes.fileSpec, classes.onreadyFileDrop):classes.fileSpec}>
                {onUpload[0] !== 100?<svg className={classes.dropIcon} width="37" height="44" viewBox="0 0 37 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M36.2218 15.875H26.0698V0.625H10.8419V15.875H0.689941L18.4559 33.6667L36.2218 15.875ZM0.689941 38.75V43.8333H36.2218V38.75H0.689941Z" fill="#656565"/>
                </svg>:<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M35.478 14.4348L22.0867 32.6087L12.5215 24.9566" stroke="#638651" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M24 46C36.1503 46 46 36.1503 46 24C46 11.8497 36.1503 2 24 2C11.8497 2 2 11.8497 2 24C2 36.1503 11.8497 46 24 46Z" stroke="#638651" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>}
                <label className={classes.fileSpecText}>
                  {
                    jsonFile[0]?
                      jsonFile[0].name
                      :
                      onDropReady[0]?
                        'Drop'
                        :
                        'Drop Mospec JSON'
                  }
                </label>
                <label className={classes.fileSpecFormat}>{
                  onUpload[0] === 100?
                    'Uploaded'
                    :
                    onUpload[0] > -1?
                      'Uploading...'
                      :
                      '.json files'
                }</label>
              </div>
            </FileDrop>
            <FileDrop onDrop={(e) => __this.handleDropVideo(e, __this)} onDragOver={() => __this.onDragOver('video')} onDragLeave={__this.onDragLeave}>
              <div className={onDropReady[1]? classNames(classes.fileSpec, classes.onreadyFileDrop):classes.fileSpec}>
                {onUpload[1] !== 100?<svg className={classes.dropIcon} width="37" height="44" viewBox="0 0 37 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M36.2218 15.875H26.0698V0.625H10.8419V15.875H0.689941L18.4559 33.6667L36.2218 15.875ZM0.689941 38.75V43.8333H36.2218V38.75H0.689941Z" fill="#656565"/>
                </svg>:<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M35.478 14.4348L22.0867 32.6087L12.5215 24.9566" stroke="#638651" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M24 46C36.1503 46 46 36.1503 46 24C46 11.8497 36.1503 2 24 2C11.8497 2 2 11.8497 2 24C2 36.1503 11.8497 46 24 46Z" stroke="#638651" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>}
                <label className={classes.fileSpecText}>
                  {
                    videoFile[0]?
                      videoFile[0].name
                      :
                      onDropReady[1]?
                        'Drop'
                        :
                        'Drop Preview Video'
                  }
                </label>
                <label className={classes.fileSpecFormat}>{
                  onUpload[1] === 100?
                    'Uploaded'
                    :
                    onUpload[1] > -1?
                      'Uploading...'
                      :
                      '.mp4 files'
                }</label>
              </div>
            </FileDrop>
            {errorShow? <div className={classes.dataError}>
              <div className={errorDetailShow? classes.dataTitle:classNames(classes.dataTitle, 'border-0 p-0')}>
                <label className="flex-grow-1">Can not add the spec, {errorTitle}</label>
                <label className="mr-20 cursor" onClick={this.hideError}>{errorDetailShow?'Hide Detail':'Show Detail'}</label>
                <svg className="cursor" onClick={this.closeError} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.5302 1.52997L10.4702 0.469971L6.00021 4.93997L1.53022 0.469971L0.470215 1.52997L4.94022 5.99997L0.470215 10.47L1.53022 11.53L6.00021 7.05997L10.4702 11.53L11.5302 10.47L7.06021 5.99997L11.5302 1.52997Z" fill="#1C1414"/>
                </svg>
              </div>
              <div className={errorDetailShow?classes.dataContent:classNames(classes.dataContent, 'height-0 m-0')}>
                {errorContent.map((err, i) => {
                  return <p key={i} className="mb-0 mt-5">keyName / nodeName is missing</p>
                })
              }
              </div>
            </div>:<></>}
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(styles, {withTheme: true})(UpdateProject)