import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import RulerLine from './RulerLine'
import Configs from '../configs/Configs'

const styles = theme => ({
  root: {
    display: 'flex',
    backgroundColor: '#030303'
  },
  leftPanel: {
    width: Configs.layout.navigationWidth,
    padding: theme.spacing(1, 0),
    textAlign: 'left',
    fontSize: 12
  }
})

class RulerLineHeader extends Component {

  currentAnchorValue = (value) => {
    this.props.changeCurrenttime(value)
  }

  render() {
    const { classes, rularSet, curTime, videoControl, played } = this.props
    
    return (
      <div className={classes.root}>
        <div className={classes.leftPanel}>
          <label style={{opacity: 0.5, marginLeft: '8px'}}>@</label>
          <label>{Math.round(curTime)} ms</label>
        </div>
        <RulerLine rularSet={rularSet} changeAnchorvalue={this.currentAnchorValue} videoControl={videoControl} curTime={curTime} played={played} />
      </div>
    )
  }
}

export default withStyles(styles, {withTheme: true})(RulerLineHeader)