import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Sidemenu from './Sidemenu'
import Contentmenu from './Contentmenu'
import UpdateProject from './UpdateProject'

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%'
  }
})

class Content extends Component {

  updateProject = (pid) => {
    this.props.updateProject(pid)
  }
  archiveProject = (pid) => {
    this.props.archiveProject(pid)
  }
  deleteProject = (pid) => {
    this.props.deleteProject(pid)
  }
  favoriteProject = (pid) => {
    this.props.favoriteProject(pid)
  }

  handleProjectNameChange = (name) => {
    this.props.handleProjectNameChange(name)
  }

  handleProjectColorChanged = (color) => {
    this.props.handleProjectColorChanged(color)
  }

  addSpec = (pid) => {
    this.props.addSpec(pid)
  }

  render() {
    const { classes, projectData, onEdit, tempProjectName, tempProjectColor, activeProjects, searchText, photoURL, displayName } = this.props
    return (
      <div className={classes.root}>
        {onEdit === -1?
          <>
            <Sidemenu photoURL={photoURL} displayName={displayName} />
            <Contentmenu projectData={projectData} activeProjects={activeProjects} searchText={searchText}
              updateProject={this.updateProject}
              archiveProject={this.archiveProject}
              deleteProject={this.deleteProject}
              favoriteProject={this.favoriteProject}
              addSpec={this.addSpec}/>
          </>
          :          
          <UpdateProject
            handleProjectNameChange={this.handleProjectNameChange}
            tempProjectName={tempProjectName}
            handleProjectColorChanged={this.handleProjectColorChanged}
            tempProjectColor={tempProjectColor} />
        }
      </div>
    )
  }
}

export default withStyles(styles, {withTheme: true})(Content)