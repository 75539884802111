import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import './App.css'

import firebase from 'firebase/app'
import '@firebase/firestore'
import 'firebase/auth'
import 'firebase/database'

import Auth from './pages/Auth'
import Main from './pages/Main'
import Menu from './pages/Menu'
import Spec from './pages/Spec'

firebase.initializeApp({
  apiKey: "AIzaSyBH-TIyMKV23lrCjg1Y5Rcno8druoyQVrA",
  authDomain: "mospecs-3fb81.firebaseapp.com",
  databaseURL: "https://mospecs-3fb81.firebaseio.com",
  projectId: "mospecs-3fb81",
  storageBucket: "mospecs-3fb81.appspot.com",
  messagingSenderId: "828058378530",
  appId: "1:828058378530:web:53ae0b54689b6776dc057b",
  measurementId: "G-TCJMT0H39K"
})

function App() {
  return (
    <div className='main'>
      <Router>
        <Route exact path="/" component={() => <Auth />} />
        <Route exact path="/project" component={() => <Menu />} />
        <Route exact path="/spec" component={() => <Spec />} />
        <Route path="/videopreview" component={() => <Main/>} />
      </Router>
    </div>
  );
}

export default App;