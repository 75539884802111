import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames'

import Configs from '../../configs/Configs'

const styles = theme => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    minHeight: 'calc(100vh - '+Configs.layout.headerHeight+'px)',
    width: Configs.layout.menuSidebarWidth,
    backgroundColor: Configs.theme.sidebarBackground,
    color: Configs.theme.color,
    padding: '20px 0'
  },
  sidemenu: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '0 38px',
    fontSize: '16px',
    lineHeight: '18px',
    color: '#9B9B9B',
    margin: '10px 0',
    height: '26px',
    cursor: 'pointer'
  },
  active: {
    boxShadow: 'inset 3px 0px #FF6059',
    color: '#FFFFFF'
  },
  sidemenuIcon: {
    marginRight: '16.25px'
  }
})

class Sidemenu extends Component {

  state = {
    hovered: -1,
    actived: 0
  }

  onMouseOver = (tid) => {
    this.setState({hovered: tid})
  }
  onMouseOut = (tid) => {
    this.setState({hovered: -1})    
  }

  render() {
    const { classes } = this.props
    const { hovered, actived } = this.state
    return (
      <div className={classes.root}>
      <div className={actived === 0 || hovered === 0? classNames(classes.sidemenu, classes.active):classes.sidemenu} onMouseOver={() => this.onMouseOver(0)} onMouseOut={() => this.onMouseOut(0)}>
          <svg className={classes.sidemenuIcon} width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.46401 5.35714C1.14196 5.35714 0.8331 5.48508 0.605377 5.7128C0.377654 5.94052 0.249721 6.24938 0.249721 6.57143C0.249721 6.89348 0.377654 7.20233 0.605377 7.43006C0.8331 7.65778 1.14196 7.78571 1.46401 7.78571H9.96401C10.2861 7.78571 10.5949 7.65778 10.8226 7.43006C11.0504 7.20233 11.1783 6.89348 11.1783 6.57143C11.1783 6.24938 11.0504 5.94052 10.8226 5.7128C10.5949 5.48508 10.2861 5.35714 9.96401 5.35714H1.46401ZM15.0235 10.2143H2.88067C2.55862 10.2143 2.24977 10.3422 2.02204 10.5699C1.79432 10.7977 1.66639 11.1065 1.66639 11.4286C1.66639 11.7506 1.79432 12.0595 2.02204 12.2872C2.24977 12.5149 2.55862 12.6429 2.88067 12.6429H15.0235C15.3456 12.6429 15.6544 12.5149 15.8822 12.2872C16.1099 12.0595 16.2378 11.7506 16.2378 11.4286C16.2378 11.1065 16.1099 10.7977 15.8822 10.5699C15.6544 10.3422 15.3456 10.2143 15.0235 10.2143ZM1.35686 2.92857H18.0235C18.3456 2.92857 18.6544 2.80064 18.8822 2.57292C19.1099 2.34519 19.2378 2.03633 19.2378 1.71429C19.2378 1.39224 19.1099 1.08338 18.8822 0.855656C18.6544 0.627933 18.3456 0.5 18.0235 0.5H1.35686C1.03482 0.5 0.725957 0.627933 0.498234 0.855656C0.270512 1.08338 0.142578 1.39224 0.142578 1.71429C0.142578 2.03633 0.270512 2.34519 0.498234 2.57292C0.725957 2.80064 1.03482 2.92857 1.35686 2.92857ZM16.0354 15.0714H6.96401C6.64196 15.0714 6.3331 15.1994 6.10538 15.4271C5.87765 15.6548 5.74972 15.9637 5.74972 16.2857C5.74972 16.6078 5.87765 16.9166 6.10538 17.1443C6.3331 17.3721 6.64196 17.5 6.96401 17.5H16.0354C16.3575 17.5 16.6663 17.3721 16.8941 17.1443C17.1218 16.9166 17.2497 16.6078 17.2497 16.2857C17.2497 15.9637 17.1218 15.6548 16.8941 15.4271C16.6663 15.1994 16.3575 15.0714 16.0354 15.0714Z" fill={actived === 0 || hovered === 0? "white":"#9B9B9B"}/>
          </svg>
          Specs
        </div>
        <div className={actived === 1 && hovered === 1? classNames(classes.sidemenu, classes.active):classes.sidemenu} onMouseOver={() => this.onMouseOver(1)} onMouseOut={() => this.onMouseOut(1)}>
          <svg className={classes.sidemenuIcon} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.2187 5.67943L4.32378 6.05888C4.49308 6.60763 4.71491 7.0513 5.3162 7.0513C5.91749 7.0513 6.48375 6.61931 6.13349 5.53348L4.4814 0.863272C4.06108 -0.315956 2.14629 -0.240065 1.78435 0.804895L0.0914003 5.56267C-0.212164 6.57844 0.284047 7.0513 0.937877 7.0513C1.59171 7.0513 1.75516 6.54342 1.86024 6.18731L2.00619 5.67943H4.2187ZM3.10369 1.86153L3.79254 4.19664H2.42067L3.10369 1.86153ZM15.9468 13.8348C17.7331 13.6597 18.2118 10.5481 14.8551 10.5481H12.3098C11.5801 10.5481 11.3349 10.9159 11.3349 11.6223V16.5435C11.3187 16.6738 11.3311 16.8061 11.3711 16.9311C11.4112 17.0561 11.4779 17.171 11.5668 17.2676C11.6557 17.3643 11.7645 17.4404 11.8858 17.4908C12.007 17.5411 12.1378 17.5645 12.269 17.5593H15.0302C18.2936 17.5593 18.1067 14.2026 15.9293 13.8406L15.9468 13.8348ZM13.3548 12.0835C16.1978 12.0835 16.1861 13.251 13.3548 13.251V12.0835ZM13.3548 16.0123V14.6988C16.6006 14.6988 16.1453 16.0298 13.3315 16.0298L13.3548 16.0123ZM14.8318 4.13242C14.2947 1.92575 10.0857 2.07169 8.02492 2.07169C7.79268 2.07169 7.56995 2.16395 7.40574 2.32817C7.24152 2.49238 7.14926 2.71511 7.14926 2.94735C7.14926 3.17959 7.24152 3.40232 7.40574 3.56654C7.56995 3.73076 7.79268 3.82302 8.02492 3.82302C8.6087 3.82302 12.7944 3.74129 13.133 4.54106C13.3081 6.63098 1.13052 9.54987 2.04121 13.3736C2.57829 15.5803 6.78732 15.4343 8.84805 15.4343C9.08029 15.4343 9.30302 15.3421 9.46724 15.1779C9.63146 15.0136 9.72372 14.7909 9.72372 14.5587C9.72372 14.3264 9.63146 14.1037 9.46724 13.9395C9.30302 13.7753 9.08029 13.683 8.84805 13.683C8.26427 13.683 4.07859 13.7647 3.74 12.965C3.57655 10.8517 15.7366 7.93281 14.8318 4.13242Z" fill={actived === 1 && hovered === 1? "white":"#9B9B9B"}/>
          </svg>
          Flows
        </div>
        <div className={actived === 2 && hovered === 2? classNames(classes.sidemenu, classes.active):classes.sidemenu} onMouseOver={() => this.onMouseOver(2)} onMouseOut={() => this.onMouseOut(2)}>
          <svg className={classes.sidemenuIcon} width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.9196 0H8.45234L10.9124 2.32082H14.7592V13.9249H10.9124L8.45234 16.2458H15.9196C16.2273 16.2458 16.5225 16.1235 16.7401 15.9059C16.9577 15.6883 17.08 15.3931 17.08 15.0853V1.16041C17.08 0.852651 16.9577 0.557496 16.7401 0.339877C16.5225 0.122257 16.2273 0 15.9196 0ZM11.6203 8.97578C11.7808 8.71388 11.8658 8.41267 11.8658 8.10547C11.8658 7.79828 11.7808 7.49707 11.6203 7.23517C10.4108 5.3305 8.79625 3.71595 6.89159 2.50649C6.70415 2.3922 6.49604 2.31595 6.27913 2.28208C6.06223 2.24822 5.84078 2.25741 5.62742 2.30914C5.41407 2.36086 5.21299 2.4541 5.03567 2.58353C4.85835 2.71296 4.70826 2.87605 4.59397 3.06349C4.36315 3.44203 4.29216 3.89677 4.39662 4.32765C4.50108 4.75854 4.77243 5.13028 5.15097 5.3611C5.53 5.60421 5.89424 5.86964 6.24176 6.15598L1.43185 6.53312C1.04115 6.56684 0.677298 6.74587 0.412191 7.03484C0.147083 7.32382 0 7.70171 0 8.09387C0 8.48603 0.147083 8.86392 0.412191 9.1529C0.677298 9.44187 1.04115 9.6209 1.43185 9.65462L6.26497 10.0318C5.91599 10.3241 5.54555 10.5898 5.15677 10.8266C4.96934 10.9409 4.80625 11.091 4.67682 11.2683C4.54738 11.4457 4.45414 11.6467 4.40242 11.8601C4.3507 12.0734 4.34151 12.2949 4.37537 12.5118C4.40923 12.7287 4.48549 12.9368 4.59978 13.1243C4.71407 13.3117 4.86416 13.4748 5.04148 13.6042C5.2188 13.7336 5.41987 13.8269 5.63322 13.8786C5.84658 13.9303 6.06803 13.9395 6.28494 13.9057C6.50184 13.8718 6.70995 13.7955 6.89739 13.6813C8.79747 12.4778 10.4098 10.8714 11.6203 8.97578Z" fill={actived === 2 && hovered === 2? "white":"#9B9B9B"}/>
          </svg>
          Transitions
        </div>
      </div>
    )
  }
}

export default withStyles(styles, {withTheme: true})(Sidemenu)