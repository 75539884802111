import React, { Component } from 'react';
import { Breadcrumbs, Link } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames'
import { Media, Player } from 'react-media-player'
import RulerLineHeader from '../components/RulerLineHeader'
import GraphSection from '../components/GraphSection'
import ExpanseListCollapse from '../components/ExpanseListCollapse'
import Configs from '../configs/Configs'
// import JsonModel from '../Data'

import CustomPlayPause from './CustomPlayPause'

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    color: Configs.theme.color
  },
  content: {
    width: 'calc(100% - ' + Configs.layout.rightPanelWidth + 'px)'
  },
  rightPanel: {
    width: Configs.layout.rightPanelWidth,
    minHeight: 'calc(100vh - '+Configs.layout.headerHeight+'px)',
    background: 'linear-gradient(0deg, #121212, #121212), #0D0D0D',
    boxShadow: 'inset 0px 1px 0px rgba(255, 255, 255, 0.03), inset 1px 0px 0px rgba(255, 255, 255, 0.06)',
    position: 'relative'
  },
  rightPanelHeader: {
    display: 'flex',
    justifyContent: 'center',
    margin: '1px 0 0 0',
    padding: theme.spacing(2.2),
    background: Configs.theme.pallet,
    boxShadow: '0px 1px 0px #000000, inset 0px 1px 0px rgba(255, 255, 255, 0.06)'
  },
  radioButton: {
    height: '23px',
    lineHeight: '19px',
    background: Configs.theme.pallet,
    color: Configs.theme.color,
    fontSize: '12px',
    textTransform: 'uppercase',
    cursor: 'pointer',
    padding: theme.spacing(0, 2),
    margin: '0 4px'
  },
  selected: {
    padding: theme.spacing(0, 2),
    background: '#000000',
    color: '#CDCDCD',
    border: '1px solid #9B9B9B',
    boxSizing: 'border-box',
    borderRadius: '179px'
  },
  breadcrumbsContent: {
    margin: '1px 0',
    padding: theme.spacing(0.5, 1),
    background: Configs.theme.pallet,
    boxShadow: '0px 1px 0px #000000, inset 0px 1px 0px rgba(255, 255, 255, 0.06)'
  },
  breadcrumbsLink: {
    fontSize: '12px',
    color: Configs.theme.color,
  },
  media: {
    width: '100%'
  },
  mediaPlayer: {
    width: 'calc( 100% - 20px )',
    display: 'flex',
    alignItems: 'center',
    height: 'calc( 100vh - 164px )',
    padding: '10px',
    justifyContent: 'center'
  },
  mediaControls: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: 'calc( 100% - 30px)',
    height: '48px',
    background: '#252525',
    boxShadow: '0px 1px 0px #000000, inset 0px 1px 0px rgba(255, 255, 255, 0.06)',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 15px'
  },
  curVideoTime: {
    fontSize: '12px',
    lineHeight: '12px',
    color: '#8E8A84'
  }
})

class Home extends Component {

  state={
    buttonName: 'preview',
    selectedLayerIndex: 0,
    selectedItemIndex: 0,
    curTime: 0,
    videoControl: false,
    played: false,
    loop: false,
    videoLoading: true
  }

  componentDidMount () {
    // console.log(JsonModel)
  }

  onHandleClickPreview = () => {
    this.setState({buttonName: 'preview'})
  }

  onHandleClickInspect = () => {
    this.setState({buttonName: 'inspect'})
  }

  onHandleListClick = (layerIndex, itemIndex, expandable) => {
    let { selectedLayerIndex, selectedItemIndex } = this.state
    selectedLayerIndex = layerIndex
    selectedItemIndex = itemIndex
    this.setState({selectedLayerIndex, selectedItemIndex})
    this.props.onChangeActiveList(expandable)
  }

  noAction = () => {

  }

  onTimeUpdate = (e) => {
    this.setState({
      curTime: e.currentTime * 1000
    })
  }

  onPlay = (e) => {
    this.setState({videoControl: true, played: true})
  }

  onPause = (e) => {
    this.setState({videoControl: true, played: false})
  }

  play = () => {
    document.getElementById("playpause").click()
  }

  changeCurrenttime = (time) => {
    this.setState({videoControl: false, curTime: time})
    let video = document.getElementById('preview_video')
    if (video) video.currentTime = time / 1000
  }

  setLoop = (loop) => {
    this.setState({loop: loop})
  }

  videoLoaded = () => {
    this.setState({videoLoading: false})
  }

  render() {
    const { classes, curveStyle, animationable, videoUrl, jsonData } = this.props
    let { buttonName, selectedLayerIndex, selectedItemIndex, curTime, videoControl, played, loop, videoLoading } = this.state
    return (
      jsonData !== undefined ?
      <div className={classes.root}>
        <div className={classes.content}>
          <RulerLineHeader rularSet={[ Math.round(jsonData.firstKeyTime * 1000), Math.round(jsonData.lastKeyTime * 1000) ]} changeCurrenttime={this.changeCurrenttime} curTime={curTime} videoControl={videoControl} played={played}/>
          <GraphSection dataSet={jsonData} animationable={animationable} onSelectedList={this.onHandleListClick} selectedLayerIndex={selectedLayerIndex} selectedItemIndex={selectedItemIndex} curveStyle={curveStyle} />
        </div>
        <div className={classes.rightPanel}>
          <div className={classes.rightPanelHeader}>
            <label className={buttonName==='preview' ? classNames(classes.radioButton, classes.selected) : classes.radioButton} onClick={this.onHandleClickPreview}>Preview</label>
            <label className={buttonName==='inspect' ? classNames(classes.radioButton, classes.selected) : classes.radioButton} onClick={this.onHandleClickInspect}>Inspect</label>
          </div>
          <div style={{display: buttonName==='preview'? 'block':'none'}}>
            <Media>
              <div className={classes.media}>
                <div className={classes.mediaPlayer}>
                  <div style={{display: videoLoading?'block':'none'}} className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                  <Player style={{display: videoLoading?'none':'block'}} id="preview_video" src={videoUrl} onDuration={this.videoLoaded} onTimeUpdate={this.onTimeUpdate} onPlay={this.onPlay} onPause={this.onPause} onClick={this.play} loop={loop} />
                </div>
                <CustomPlayPause setLoop={this.setLoop}/>
              </div>
            </Media>
          </div>
          <div style={{display: buttonName==='inspect'? 'block':'none'}}>
            <div className={classes.breadcrumbsContent}>
              <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbsLink}>
                <Link color="inherit" href="/">
                  {jsonData.layers[selectedLayerIndex].name}
                </Link>
                <Link color="inherit" href="/">
                  {jsonData.layers[selectedLayerIndex].props[selectedItemIndex].name}
                </Link>
              </Breadcrumbs>
            </div>
            <ExpanseListCollapse open={true} onSelectThis={this.noAction} dataSet={jsonData.layers[selectedLayerIndex].props[selectedItemIndex]}/>
          </div>
        </div>
      </div> :
      <></>
    )
  }
}

export default withStyles(styles, {withTheme: true})(Home)