import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { List } from '@material-ui/core'

import Configs from '../configs/Configs'

const styles = theme => ({
  root: {
    width: '100%',
    background: Configs.theme.pallet,
    boxShadow: '0px 1px 0px #000000, inset 0px 1px 0px rgba(255, 255, 255, 0.06)',
    height: 'inherit'
  },
  listContent: {
    padding: theme.spacing(0),
    height: 'inherit'
  },
  listItem: {
    width: '100%',
    height: Configs.layout.listHeight,
    padding: theme.spacing(0, 1),
    marginBottom: '1px',
    background: Configs.theme.pallet,
    boxShadow: '0px 1px 0px #000000, inset 0px 1px 0px rgba(255, 255, 255, 0.06)',
  },
  listItemIcon: {
    color: Configs.theme.color,
    minWidth: 0
  },
  borderLabel: {
    background: '#1E1E1E',
    border: '1px solid #000000',
    boxSizing: 'border-box',
    boxShadow: "0px 0.5px 0px rgba(255, 255, 255, 0.05)",
    borderRadius: '2.5px',
    color: '#FFF6EA',
    fontWeight: 'bold',
    fontSize: '13px',
    lineHeight: '13px',
    padding: '4px 6px',
    marginRight: '8px',
    textAlign: 'center',
    textTransform: 'uppercase',
    opacity: 0.5
  },
  borderLabelActive: {
    background: 'rgba(235, 177, 94, 0.5)',
    border: '1px solid #EBB15E',
    boxShadow: "0px 1px 0px rgba(255, 255, 255, 0.05)",
    opacity: 1
  },
  itemText: {
    fontSize: '14px'
  },
  graphItem: {
    // padding: theme.spacing(0, 1),
    marginBottom: '1px',
    height: 'inherit'
    // background: Configs.theme.graph,
    // opacity: 1,
    // transition: 'height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    // transitionDuration: '261ms'
  }
})

// Draw spec frame of Graph panel
function DrawSpecFrame(props) {

  // calculate graph panel width
  let dimensions = window.innerWidth - Configs.layout.navigationWidth - Configs.layout.rightPanelWidth
  // calculate min width of ruler spec 
  let virtualUnit = Math.floor((props.rularSet[1] - props.rularSet[0]) / Math.ceil(dimensions / Configs.rular.minWidth) / 10 ) * 10

  let startX = 0
  let endX = dimensions
  let startY = 0
  let endY = Configs.layout.listHeight + Configs.layout.childlistHeight * 5

  let specX = [], specY = []

  // step value of X and Y axios
  let martrixWidth = Math.round(endX * virtualUnit / (props.rularSet[1] - props.rularSet[0]))
  let martrixHeight = props.isOpen? Math.round( (Configs.layout.listHeight + Configs.layout.childlistHeight * 5) / 6 ) : Math.round( Configs.layout.listHeight / 2 )

  for (let i=startX; i<endX; i++) {
    if (i % martrixWidth === 0) {
      specX.push(i)
    }
  }

  for (let j=endY; j>=startY; j--) {
    if (j % martrixHeight === 0) {
      specY.push(j)
    }
  }

  // let fillColor = props.selectedStyle? props.selectedStyle.background : Configs.theme.curveFillColur
  return ( specY.map(y => {
    return (
      specX.map((x, index) => {
        return (
          props.alternate % 2 === 0?
            <rect key={index} x={x+1} y={y} width={martrixWidth} height={martrixHeight} style={{ fill: props.selectedStyle? props.selectedStyle.background :'#0D0D0D', stroke: Configs.theme.graphSpecFrameColor, strokeWidth: 1, fillOpacity: props.selectedStyle? 0.15 : 0.55, strokeOpacity: 0.9}} />
            :
            <rect key={index} x={x+1} y={y} width={martrixWidth} height={martrixHeight} style={{ fill: props.selectedStyle? props.selectedStyle.background :'linear-gradient(0deg, rgba(27, 27, 27, 0.4), rgba(27, 27, 27, 0.4)), #0D0D0D', stroke: Configs.theme.graphSpecFrameColor, strokeWidth: 1, fillOpacity: props.selectedStyle? 0.15 : 0.25, strokeOpacity: 0.9}} />
        )
      })
    )
  }))
    
}

// Drawing curve of layer
function DrawCurve(props) {
  
  // calculate graph panel width
  let dimensions = window.innerWidth - Configs.layout.navigationWidth - Configs.layout.rightPanelWidth

  // get codinate from value of interpolation bezier
  let xyValueArray = props.dataSet.interpolation? props.dataSet.interpolation.bezier.replace(/\(|\)/g, '').split(',') : [0,0,0,0]
  let x1 = parseFloat(xyValueArray[0]), y1 = parseFloat(xyValueArray[1]), x2 = parseFloat(xyValueArray[2]), y2 = parseFloat(xyValueArray[3])
  // let x1 = Math.random(), y1 = Math.random(), x2 = Math.random(), y2 = Math.random()

  // draw curve function
  // let canvasHeight = props.isOpen? Configs.layout.listHeight + Configs.layout.childlistHeight * 5 : Configs.layout.listHeight
  let canvasHeight = Configs.layout.listHeight

  let startX = props.dataSet.startTime * 1000 * dimensions / props.rularSet[1]
  let startY = " " + canvasHeight + " "
  
  let endX = props.dataSet.endTime * 1000 * dimensions / props.rularSet[1]
  let endY = " " + 0 + " "

  let distanceY = canvasHeight // endValue - startValue

  let controlPointX1 = ((endX - startX) * x1 + startX) + " "
  let controlPointY1 = (canvasHeight - (distanceY * y1)) + " "

  let controlPointX2 = ((endX - startX) * x2 + startX) + " "
  let controlPointY2 = (canvasHeight - (distanceY * y2)) + " "

  let fillColor = props.selectedStyle.background? props.selectedStyle.background : Configs.theme.curveFillColur
  // Configs.theme.curveStorkColur
  let addClassName = props.animationable? 'Stork' : 'Fix'
  return props.curveStyle === 'line'? <g fill={fillColor} stroke={props.hasColorStyle.curveColor}>
    { props.selectedStyle.background? 
      <path className={props.isOpen? 'expandno' + addClassName : 'contractno' + addClassName} d={"M " + startX + startY + "C " + controlPointX1 + controlPointY1 + controlPointX2 + controlPointY2 + endX + endY + " L " + endX + " " + canvasHeight} strokeWidth="0"/> : <></>
    }
    <path className={props.isOpen? 'expand' : 'contract'} d={"M " + startX + startY + "C " + controlPointX1 + controlPointY1 + controlPointX2 + controlPointY2 + endX + endY} strokeWidth="3" fill="none"/>
  </g>
  :
  <g fill={fillColor} stroke={Configs.theme.curveStorkColur}>
    <path className={props.isOpen? 'expandno' + addClassName : 'contractno' + addClassName} d={"M " + startX + startY + "C " + controlPointX1 + controlPointY1 + controlPointX2 + controlPointY2 + endX + endY + " L " + endX + " " + canvasHeight} strokeWidth="0"/>
    <path className={props.isOpen? 'expand' : 'contract'} d={"M " + startX + startY + "C " + controlPointX1 + controlPointY1 + controlPointX2 + controlPointY2 + endX + endY} strokeWidth="3" fill="none"/>
  </g>
    
}

class Graph extends Component {
  
  targetDiv = React.createRef()

  render() {
    const { classes, dataSet, isOpen, rularSet, selectedStyle, curveStyle, hasColorStyle, alternate, animationable } = this.props

    return (
      <div className={classes.root}>
        <List
          component="nav"
          className={classes.listContent}
        >
          {/* style={{height: isOpen? (Configs.layout.listHeight + Configs.layout.childlistHeight * 5) + 'px' : Configs.layout.listHeight +'px', overflowY: "hidden"}} */}
          <div className={classes.graphItem} ref={this.targetDiv}>
            {/* height={isOpen? Configs.layout.listHeight + Configs.layout.childlistHeight * 5 : Configs.layout.listHeight} */}
            <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
              <DrawSpecFrame alternate={alternate} rularSet={rularSet} isOpen={isOpen} selectedStyle={selectedStyle} height={this.targetDiv} />
              <DrawCurve dataSet={dataSet} animationable={animationable} rularSet={rularSet} isOpen={isOpen} selectedStyle={selectedStyle} hasColorStyle={hasColorStyle} curveStyle={curveStyle}  height={this.targetDiv} />
            </svg>
          </div>
        </List>
      </div>
    )
  }
}

export default withStyles(styles, {withTheme: true})(Graph)