import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { List, ListItem, ListItemIcon } from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import ExpanseListCollapse from './ExpanseListCollapse'
import Graph from './Graph'

import Configs from '../configs/Configs'

const styles = theme => ({
  root: {
    width: '100%',
    background: '#030303',
  },
  listContent: {
    width: '100%',
    padding: theme.spacing(0),
    borderBottom: '3px solid black'
  },
  listItem: {
    width: '100%',
    height: Configs.layout.listHeight,
    padding: theme.spacing(0, 1),
    marginBottom: '1px',
    background: Configs.theme.pallet,
    boxShadow: '0px 1px 0px #000000, inset 0px 1px 0px rgba(255, 255, 255, 0.06), inset -2px 0px 0px #000000;',
  },
  listItemIcon: {
    color: Configs.theme.color,
    minWidth: 0
  },
  borderLabel: {
    background: '#1E1E1E',
    border: '1px solid #000000',
    boxSizing: 'border-box',
    boxShadow: "0px 0.5px 0px rgba(255, 255, 255, 0.05)",
    borderRadius: '2.5px',
    color: '#FFF6EA',
    fontWeight: 'bold',
    fontSize: '13px',
    lineHeight: '13px',
    padding: '4px 6px',
    marginRight: '8px',
    textAlign: 'center',
    textTransform: 'uppercase',
    opacity: 0.5
  },
  borderLabelActive: {
    background: 'rgba(235, 177, 94, 0.5)',
    border: '1px solid #EBB15E',
    boxShadow: "0px 1px 0px rgba(255, 255, 255, 0.05)",
    opacity: 1
  },
  itemText: {
    fontSize: '14px'
  },
  graphPanel: {
    width: 'calc( 100% - ' + Configs.layout.navigationWidth + 'px )',
    marginBottom: '1px',
    background: Configs.theme.graph,
    opacity: 1,
    transition: 'height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    transitionDuration: '261ms'
  },
  sectionListPanel: {
    display: 'flex',
    marginTop: '1px',
    marginBottom: '1px',
  },
  leftPanel: {
    width: Configs.layout.navigationWidth
  }
})

class ExpanseList extends Component {

  state = {
    listItemOpens: [],
    listItemStyles: [],
  }

  componentDidMount () {
    const { dataSet } = this.props
    const { listItemOpens, listItemStyles } =this.state
    dataSet.forEach((prop) => {
      listItemOpens.push(false)
      listItemStyles.push(Configs.theme.labelStyleSet[prop.name])      
    })
    this.setState({listItemOpens, listItemStyles})
  }

  // handler list click
  handleListItemClick = (layerIndex, itemIndex, expandable) => {
    this.props.onSelected(layerIndex, itemIndex, expandable)
    if (expandable) {
      let { listItemOpens } =this.state
      listItemOpens = listItemOpens.map((item, idx) => {
        return itemIndex === idx ? item = !item : item
      })
      this.setState({listItemOpens})
    }
  }

  render() {
    const { classes, dataSet, rularSet, selectedItemIndex, selectedLayerIndex, layerIndex, curveStyle, animationable } = this.props
    const { listItemOpens, listItemStyles } =this.state
    return (
      <div className={classes.root}>
        <List
          component="nav"
          className={classes.listContent}
        >
          {
            dataSet.map((prop, index) => {
              return (
                prop !== undefined &&
                <div key={index} className={classes.sectionListPanel}>
                  <div className={classes.leftPanel}>
                    <ListItem button className={classes.listItem} onClick={(e) => this.handleListItemClick(layerIndex, index, true)}>
                      <ListItemIcon className={classes.listItemIcon}>
                        { listItemOpens[index] ? <ExpandMoreIcon /> : <NavigateNextIcon /> }
                      </ListItemIcon>
                      <div className={classes.borderLabel} style={selectedLayerIndex === layerIndex && selectedItemIndex === index ? {...listItemStyles[index]} : {}}>{Configs.theme.labelStyleSet[prop.name]? Configs.theme.labelStyleSet[prop.name].string : prop.name[0]}</div>
                      <label className={classes.itemText}>{prop.name}</label>
                    </ListItem>
                    <ExpanseListCollapse open={listItemOpens[index]} dataSet={prop} layerIndex={layerIndex} index={index} onSelectThis={this.handleListItemClick}/>
                  </div>
                  <div className={classes.graphPanel} style={{height: listItemOpens[index]? (Configs.layout.listHeight + Configs.layout.childlistHeight * 5 + 1) + 'px' : Configs.layout.listHeight +'px', overflowY: "hidden"}} onClick={(e) => this.handleListItemClick(layerIndex, index, false)}>
                    <Graph dataSet={prop} animationable={animationable} alternate={index} isOpen={listItemOpens[index]} rularSet={rularSet} selectedStyle={selectedLayerIndex === layerIndex && selectedItemIndex === index ? {...listItemStyles[index]} : {}} curveStyle={curveStyle} hasColorStyle={{...listItemStyles[index]}} />
                  </div>
                </div>
              )
            })
          }
        </List>
      </div>
    )
  }
}

export default withStyles(styles, {withTheme: true})(ExpanseList)