const Configs = {
  layout: {
    headerHeight: 58,
    navigationWidth: 211,
    menuSidebarWidth: 228,
    rightPanelWidth: 354,
    rularHeight: 34,
    listHeight: 51,
    childlistHeight: 29,
    loadingBarWidth: 293
  },
  rular: {
    minWidth: 50
  },
  colors: [
    "#6AA2D4", "#78B040", "#929EA4", "#8B64C9", "#E1788F", "#E8B342", "#E98755", "#E95446", "#E95447"
  ],
  theme: {
    color: '#858585',
    background: '#030303',
    pallet: '#252525',
    graph: '#030303',
    sidebarBackground: '#151515',
    curveFillColur: 'rgba(0, 0, 0, 0.4)',
    curveStorkColur: 'rgba(255, 255, 255, 0.25)',
    graphSpecFrameColor: 'rgba(255, 255, 255, 0.03)',
    labelStyleSet: {
      Opacity: {
        string: 'T',
        background: 'rgba(101, 161, 86, 0.8)',
        curveColor: 'rgba(101, 161, 86, 1)',
        border: '1px solid #65A156',
        boxShadow: '0px 1px 0px rgba(255, 255, 255, 0.05)',
        opacity: 1
      },
      Anchor: {
        string: 'A',
        background: 'rgba(235, 177, 94, 0.8)',
        curveColor: 'rgba(235, 177, 94, 1)',
        border: '1px solid #EBB15E',
        boxShadow: "0px 1px 0px rgba(255, 255, 255, 0.05)",
        opacity: 1
      },
      Position: {
        string: 'P',
        background: 'rgba(230, 124, 119, 0.8)',
        curveColor: 'rgba(230, 124, 119, 1)',
        border: '1px solid #E67C77',
        boxShadow: "0px 1px 0px rgba(255, 255, 255, 0.05)",
        opacity: 1
      },
      Rotation: {
        string: 'R',
        background: 'rgba(129, 55, 149, 0.8)',
        curveColor: 'rgba(129, 55, 149, 1)',
        border: '1px solid #813795',
        boxShadow: '0px 1px 0px rgba(255, 255, 255, 0.05)',
        opacity: 1
      },
      Scale: {
        string: 'S',
        background: 'rgba(92, 159, 164, 0.8)',
        curveColor: 'rgba(92, 159, 164, 1)',
        border: '1px solid #5C9FA4',
        boxShadow: '0px 1px 0px rgba(255, 255, 255, 0.05)',
        opacity: 1
      },
      Color: {
        string: 'C',
        background: 'rgba(164, 150, 122, 0.8)',
        curveColor: 'rgba(164, 150, 122, 1)',
        border: '1px solid #A4967A',
        boxShadow: '0px 1px 0px rgba(255, 255, 255, 0.05)',
        opacity: 1
      },
      CornerRadius: {
        string: 'D',
        background: 'rgba(128, 110, 46, 0.8)',
        curveColor: 'rgba(128, 110, 46, 1)',
        border: '1px solid #806E2E',
        boxShadow: '0px 1px 0px rgba(255, 255, 255, 0.05)',
        opacity: 1
      },
      StrokeWidth: {
        string: 'K',
        background: 'rgba(107, 126, 217, 0.8)',
        curveColor: 'rgba(107, 126, 217, 1)',
        border: '1px solid #6B7ED9',
        boxShadow: '0px 1px 0px rgba(255, 255, 255, 0.05)',
        opacity: 1
      },
      MaskPath: {
        string: 'M',
        background: 'rgba(145, 86, 55, 0.8)',
        curveColor: 'rgba(145, 86, 55, 1)',
        border: '1px solid #915637',
        boxShadow: '0px 1px 0px rgba(255, 255, 255, 0.05)',
        opacity: 1
      },
      Effect: {
        string: 'E',
        background: 'rgba(63, 89, 126, 0.8)',
        curveColor: 'rgba(63, 89, 126, 1)',
        border: '1px solid #3F597E',
        boxShadow: '0px 1px 0px rgba(255, 255, 255, 0.05)',
        opacity: 1
      }
    }
  }
}

export default Configs