import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import classNames from 'classnames'

import Configs from '../../configs/Configs'

import { createBrowserHistory } from 'history'
const history = createBrowserHistory({forceRefresh: true})

const styles = theme => ({
  root: {
    display: 'flex',
    flexFlow: 'wrap',
    minHeight: 'calc(100vh - '+Configs.layout.headerHeight+'px)',
    padding: '26px',
    color: Configs.theme.color,
    width: 'calc(100% - ' + Configs.layout.menuSidebarWidth + 'px)',
    alignContent: 'flex-start'
  },
  spec: {
    boxSizing: 'border-box',
    border: '1px solid transparent',
    padding: '20px 30px',
    position: 'relative',
    height: '154px',
    margin: '2px'
  },
  hovered: {
    background: 'rgba(255, 96, 89, 0.05)',
    border: '1px solid rgba(255, 96, 89, 0.25)',
    borderRadius: '6px'
  },
  specName: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '15px',
    textAlign: 'center',
    color: '#9B9B9B',
    marginTop: '19.96px',
    textTransform: 'capitalize'
  },
  popoverList: {
    padding: '0 9px 0 20px',
    borderTop: '2px solid #504F4C',
    cursor: 'pointer'
  },
  popoverIcon: {
    cursor: 'pointer',
    position: 'absolute',
    top: '8.33px',
    right: '10.25px'
  }
})

class Contentmenu extends Component {

  state = {
    anchorEl: null,
    currentSpecID: 0,
    hovered: 0,
    ihovered: false
  }

  handleClick = (event, sid) => {
    this.setState({currentSpecID: sid, anchorEl: event.currentTarget});
  };

  handleClose = () => {
    this.setState({anchorEl: null, currentSpecID: 0});
  }

  handleEditSpec = (sid) => {
    this.setState({anchorEl: null, currentSpecID: 0})
    this.props.editSpec(sid)
  }

  handleDeleteSpec = (sid) => {
    this.setState({anchorEl: null, currentSpecID: 0})
    this.props.deleteSpec(sid)
  }

  handleShareLinkSpec = (pid, sid) => {
    this.setState({anchorEl: null, currentSpecID: 0})
    // copy
    const el = document.createElement('textarea');
    el.value = window.location.hostname + '/videopreview?pid=' + pid + '&sid=' + sid;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  onmouseoverSpec = (sid) => {
    this.setState({hovered: sid})
  }

  onmouseoutSpec = (sid) => {    
    this.setState({hovered: 0})
  }

  popHovered = (ihovered) => {
    if (ihovered) this.setState({ihovered: true})
    else this.setState({ihovered: false})
  }

  goToCurve = (pid, sid, e) => {
    const { ihovered } = this.state
    if (pid && sid && !ihovered) {
      history.push({
        pathname: '/videopreview',
        state: {
          pid: pid,
          sid: sid,
          preview: true
        }
      })
    }
  }
  
  render() {
    const { anchorEl, currentSpecID, hovered } = this.state
    const open = Boolean(anchorEl);
    const id = 'project-popover';
    const { classes, specData, searchText, pid } = this.props

    return (
      <div className={classes.root}>
        {
          specData.map((spec, index) => {
            if (searchText === '' || (searchText !== '' && spec.name.toLowerCase().includes(searchText.toLowerCase()))) {
              return (<div className={(spec && spec.id === hovered) || (spec && spec.id === currentSpecID)? classNames(classes.spec, classes.hovered): classes.spec} key={index} onMouseOver={() => this.onmouseoverSpec(spec.id)} onMouseOut={() => this.onmouseoutSpec(spec.id)} onClick={(e) => this.goToCurve(pid, spec.id, e)}>
                <svg width="110" height="80" viewBox="0 0 110 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="1.00098" y="1" width="108" height="77.0432" fill="#1E1E1E" stroke="#252525" strokeWidth="2"/>
                  <g filter="url(#filter0_i)">
                  <rect y="0.068512" width="18" height="78.9327" fill="#252525"/>
                  </g>
                  <path opacity="0.25" fillRule="evenodd" clipRule="evenodd" d="M80.0357 19.0415L25.0762 19.0415V13.0415L80.0357 13.0415V19.0415Z" fill="#FF6059"/>
                  <path opacity="0.25" fillRule="evenodd" clipRule="evenodd" d="M87.8449 31.0415L46.7949 31.0415V25.0415L87.8449 25.0415V31.0415Z" fill="#EBB15E"/>
                  <path opacity="0.25" fillRule="evenodd" clipRule="evenodd" d="M99.7897 43.0411L30.2871 43.0411V37.0411L99.7897 37.0411V43.0411Z" fill="#908670"/>
                  <path opacity="0.25" fillRule="evenodd" clipRule="evenodd" d="M80.2444 55.0416H47.665V49.0416H80.2444V55.0416Z" fill="#3C9DAA"/>
                  <path opacity="0.25" fillRule="evenodd" clipRule="evenodd" d="M62.8674 67.0414H30.2881V61.0414H62.8674V67.0414Z" fill="#3C9DAA"/>
                  <g filter="url(#filter1_dd)">
                  <rect x="63" y="11.5406" width="1" height="55.4595" fill="#5E5E5E"/>
                  </g>
                  <g filter="url(#filter2_dd)">
                  <path d="M63.4757 13.6128L67.9515 10H59L63.4757 13.6128Z" fill="#5E5E5E"/>
                  </g>
                  <defs>
                  <filter id="filter0_i" x="-0.5" y="0.068512" width="18.5" height="78.9327" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                  <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                  <feOffset dx="-0.5"/>
                  <feGaussianBlur stdDeviation="0.5"/>
                  <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.1 0"/>
                  <feBlend mode="normal" in2="shape" result="effect1_innerShadow"/>
                  </filter>
                  <filter id="filter1_dd" x="62" y="11.5406" width="3" height="55.4595" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                  <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                  <feOffset dx="1"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                  <feOffset dx="-1"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
                  <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow" result="shape"/>
                  </filter>
                  <filter id="filter2_dd" x="57.5" y="10" width="11.9515" height="3.61277" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                  <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                  <feOffset dx="1.5"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                  <feOffset dx="-1.5"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
                  <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow" result="shape"/>
                  </filter>
                  </defs>
                </svg>
                <p className={classes.specName}>{spec.name}</p>
                <svg className={classes.popoverIcon} style={{display: (spec && spec.id === hovered) || (spec && spec.id === currentSpecID)?'block':'none'}} onMouseOver={() => this.popHovered(true)} onMouseOut={() => this.popHovered(false)} onClick={(e) => this.handleClick(e, spec.id)} width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.375 2.625H12.625C12.9234 2.625 13.2095 2.50647 13.4205 2.29549C13.6315 2.08452 13.75 1.79837 13.75 1.5C13.75 1.20163 13.6315 0.915483 13.4205 0.704505C13.2095 0.493526 12.9234 0.375 12.625 0.375H1.375C1.07663 0.375 0.790483 0.493526 0.579505 0.704505C0.368526 0.915483 0.25 1.20163 0.25 1.5C0.25 1.79837 0.368526 2.08452 0.579505 2.29549C0.790483 2.50647 1.07663 2.625 1.375 2.625ZM12.625 4.875H1.375C1.07663 4.875 0.790483 4.99353 0.579505 5.2045C0.368526 5.41548 0.25 5.70163 0.25 6C0.25 6.29837 0.368526 6.58452 0.579505 6.7955C0.790483 7.00647 1.07663 7.125 1.375 7.125H12.625C12.9234 7.125 13.2095 7.00647 13.4205 6.7955C13.6315 6.58452 13.75 6.29837 13.75 6C13.75 5.70163 13.6315 5.41548 13.4205 5.2045C13.2095 4.99353 12.9234 4.875 12.625 4.875ZM12.625 9.375H1.375C1.07663 9.375 0.790483 9.49353 0.579505 9.7045C0.368526 9.91548 0.25 10.2016 0.25 10.5C0.25 10.7984 0.368526 11.0845 0.579505 11.2955C0.790483 11.5065 1.07663 11.625 1.375 11.625H12.625C12.9234 11.625 13.2095 11.5065 13.4205 11.2955C13.6315 11.0845 13.75 10.7984 13.75 10.5C13.75 10.2016 13.6315 9.91548 13.4205 9.7045C13.2095 9.49353 12.9234 9.375 12.625 9.375Z" fill="#9B9B9B"/>
                </svg>
              </div>)
            }
            return <div key={index}></div>
          })
        }
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <div className={classes.popoverList} onClick={() => this.handleEditSpec(currentSpecID)}>Edit...</div>
          <div className={classes.popoverList} onClick={() => this.handleDeleteSpec(currentSpecID)}>Delete...</div>
          <div className={classes.popoverList} onClick={() => this.handleShareLinkSpec(pid, currentSpecID)}>Copy Sharable Link</div>
        </Popover>
      </div>
    )
  }
}

export default withStyles(styles, {withTheme: true})(Contentmenu)