import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import Configs from '../../configs/Configs'

const styles = theme => ({
  root: {
    display: 'flex',
    flexFlow: 'wrap',
    minHeight: 'calc(100vh - ' + Configs.layout.headerHeight + 'px)',
    padding: '31px',
    color: Configs.theme.color,
    width: '100%'
  },
  inputProject: {
    height: 'inherit',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexFlow: 'column'
  },
  inputProjectName: {
    width: '407px',
    background: 'transparent',
    border: 0,
    borderBottom: '1px solid #323232',
    fontSize: '22px',
    lineHeight: '25px',
    color: '#A6A8AB',
    padding: '14px 10px',
    textTransform: 'capitalize'
  },
  inputProjectColorLabel: {
    fontSize: '16px',
    lineHeight: '18px',
    color: '#A6A8AB',
    opacity: '0.5',
    textAlign: 'left',
    width: '427px',
    marginTop: '28px',
    marginBottom: '11px'
  },
  inputProjectColor: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '427px'
  },
  colorTick: {
    width: '27px',
    height: '27px',
    border: '1px solid #000000', // dashed
    boxSizing: 'border-box',
    borderRadius: '4px',
    cursor: 'pointer'
  }
})

class UpdateProject extends Component {

  handleProjectNameChange = (e) => {
    this.props.handleProjectNameChange(e.target.value)
  }

  pickProjectColor = (color) => {
    this.props.handleProjectColorChanged(color)
  }
  
  render() {
    const { classes, tempProjectName, tempProjectColor } = this.props

    return (
      <div className={classes.root}>
        <div className={classes.inputProject}>
          <input className={classes.inputProjectName} id="input-project-name" type="text" placeholder="Project Name" onChange={(e) => this.handleProjectNameChange(e)} value={tempProjectName}/>
          <label className={classes.inputProjectColorLabel}>Project color</label>
          <div className={classes.inputProjectColor}>
            {
              Configs.colors.map((color, index) => {
                return (<div key={index} className={classes.colorTick} style={{background: color, border: tempProjectColor === color? '2px solid #FFFFFF':''}} onClick={() => this.pickProjectColor(color)}></div>)
              })
            }
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(styles, {withTheme: true})(UpdateProject)