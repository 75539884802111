import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import Configs from '../configs/Configs'

const styles = theme => ({
  root: {
    display: 'flex',
    width: 'calc( 100% - ' + Configs.layout.navigationWidth + 'px )'
  },
  anchor: {
    position: 'absolute',
    height: 0,
    width: window.innerWidth - Configs.layout.navigationWidth - Configs.layout.rightPanelWidth - 10,
    zIndex: 999999997
  },
  cursor: {
    cursor: 'e-resize',
    zIndex: 999999999
  },
  rulerline: {
    zIndex: 999999998
  },
  ruler: {
    zIndex: 999999990
  }
})

// Draw spec frame of ruler
function DrawSpecFrame(props) {

  let startX = 0
  let endX = window.innerWidth - Configs.layout.navigationWidth - Configs.layout.rightPanelWidth

  let virtualUnit = Math.floor(props.rularSet[1] / Math.ceil(endX / Configs.rular.minWidth) / 10 ) * 10

  let specX = []

  let martrixWidth = Math.round(endX * virtualUnit / props.rularSet[1])

  for (let i=startX; i<endX; i++) {
    if (i % martrixWidth === 0) {
      specX.push(i)
    }
  }

  return specX.map((x, index) => {
    return (
      <g key={index}>
        {
          x? (
            <g>
              <line x1={x} y1="20" x2={x} y2="31" style={{stroke: "#373737", strokeWidth: 1}} />
              {/* ruler text */}
              <text x={x} y="14" fontFamily="Verdana" fontSize="10" fill="#585858" textAnchor="middle">{index * virtualUnit}</text>
            </g>
          ) : (
            <g>
              <line x1={x + 1} y1="20" x2={x + 1} y2="31" style={{stroke: "#373737", strokeWidth: 1}} />
              <text x={x} y="14" fontFamily="Verdana" fontSize="10" fill="#585858" >0 ms</text>
            </g>
          )
        }
        <line x1={x + martrixWidth / 4} y1="24" x2={x + martrixWidth / 4} y2="30" style={{stroke: "#373737", strokeWidth: 1}} />
        <line x1={x + martrixWidth / 2} y1="24" x2={x + martrixWidth / 2} y2="30" style={{stroke: "#373737", strokeWidth: 1}} />
        <line x1={x + martrixWidth / 4 * 3} y1="24" x2={x + martrixWidth / 4 * 3} y2="30" style={{stroke: "#373737", strokeWidth: 1}} />
      </g>
    )
  })

}

let pixelWidth = window.innerWidth - Configs.layout.navigationWidth - Configs.layout.rightPanelWidth
let seekbarInterval = null

function intervalSeekbar (anchorX, videoControl, curTime, played, max) {
  let seekbar = document.getElementById('seekbar')
  if (videoControl) {
    if (seekbarInterval) clearInterval(seekbarInterval)
    seekbarInterval = setInterval(() => {
      if (curTime + 10 >= max) {
        clearInterval(seekbarInterval)
        seekbar.style.left = (pixelWidth - 10) + "px"
      }
      if (!played) {
        clearInterval(seekbarInterval)
        if (curTime >= max) seekbar.style.left = (pixelWidth - 10) + "px"
        else seekbar.style.left = (curTime * pixelWidth / max - 10) + "px"
      }
      curTime += 10
      if (curTime >= max) seekbar.style.left = (pixelWidth - 10) + "px"
      else seekbar.style.left = (curTime * pixelWidth / max - 10) + "px"
    }, 10)
  } else seekbar.style.left = (anchorX - 10) + "px"
}

class RulerLine extends Component {

  constructor(props) {
    super(props)
    this.scrollDiv = React.createRef()
  }

  componentDidUpdate () {
    intervalSeekbar(this.state.anchorX, this.props.videoControl, this.props.curTime, this.props.played, this.props.rularSet[1])
  }

  state = {
    anchorX: 0,
    dragableRuler: false
  }

  // When mouse click, move anchor to clicked position
  handleClick = (e) => {
    let { anchorX } =this.state
    anchorX = e.clientX - Configs.layout.navigationWidth
    if (anchorX >= 0 && anchorX < window.innerWidth - Configs.layout.navigationWidth - Configs.layout.rightPanelWidth) {
      this.setState({anchorX})
      let value = Math.round(anchorX * this.props.rularSet[1] / (window.innerWidth - Configs.layout.navigationWidth - Configs.layout.rightPanelWidth))
      this.props.changeAnchorvalue(value)
    }
  }

  onMouseDown = (e) => {
    this.scrollDiv.current.style.height = (window.innerHeight - Configs.layout.headerHeight) + 'px'
    this.setState({dragableRuler: true})
    let { anchorX } =this.state
    anchorX = e.clientX - Configs.layout.navigationWidth
    if (anchorX >= 0 && anchorX < window.innerWidth - Configs.layout.navigationWidth - Configs.layout.rightPanelWidth) {
      this.setState({anchorX})
      let value = Math.ceil(anchorX / (window.innerWidth - Configs.layout.navigationWidth - Configs.layout.rightPanelWidth) * this.props.rularSet[1] )
      this.props.changeAnchorvalue(value)
    }
  }

  onMouseMove = (e) => {
    let { anchorX, dragableRuler } =this.state
    anchorX = e.clientX - Configs.layout.navigationWidth
    if (anchorX >= 0 && anchorX < window.innerWidth - Configs.layout.navigationWidth - Configs.layout.rightPanelWidth && dragableRuler) {
      this.setState({anchorX})
      let value = Math.ceil(anchorX / (window.innerWidth - Configs.layout.navigationWidth - Configs.layout.rightPanelWidth) * this.props.rularSet[1])
      this.props.changeAnchorvalue(value)
    }
  }

  onMouseUp = (e) => {
    this.scrollDiv.current.style.height = '0px'
    this.setState({dragableRuler: false})
  }

  render() {
    const { classes, rularSet } = this.props // , currentTime, duration, played
    const { anchorX } =this.state
    return (
      <div className={classes.root}>
        <svg className={classes.ruler} width="100%" height={Configs.layout.rularHeight} xmlns="http://www.w3.org/2000/svg" onClick={this.handleClick}>
          <DrawSpecFrame rularSet={rularSet} />
        </svg>
        <div className={classes.anchor} onMouseMove={this.onMouseMove} ref={this.scrollDiv}>
          <svg id="seekbar" width="20" height={(window.innerHeight - Configs.layout.headerHeight)} style={{
            position: 'absolute',
            left: (anchorX - 10) + "px",
          }} className={classes.rulerline} xmlns="http://www.w3.org/2000/svg">
            <g width="20" strokeWidth="20" className={classes.cursor} draggable onMouseDown={this.onMouseDown} onMouseUp={this.onMouseUp}>
              <g filter="url(#filter0_dd)">
                <rect x={10 - 0.5} width="1" height={window.innerHeight} fill="#818181"/>
                <rect x={10 - 7.5} width="15" height={window.innerHeight} fill="transparent"/>
              </g>
              <defs>
                <filter id="filter0_dd" x={10 - 1.5} y="0" width="3" height={window.innerHeight} filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                <feOffset dx="1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                <feOffset dx="-1"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"/>
                <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow" result="shape"/>
                </filter>
              </defs>
              <path d={"M " + (10 - 4.5) + " " + 0 + " L " + 10 + " " + 4.5 + " L " + (10 + 4.5) + " " + 0 + " Z "} strokeWidth="0" fill="#818181"/>
            </g>
          </svg>
        </div>
      </div>
    )
  }
}

export default withStyles(styles, {withTheme: true})(RulerLine)