import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'

import firebase from 'firebase/app'
import '@firebase/firestore'
import 'firebase/auth'
import 'firebase/database'

import { createBrowserHistory } from 'history'
const history = createBrowserHistory({forceRefresh: true})

const styles = theme => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    position: 'relative'
  },
  loginBtn: {
    background: '#FF6059',
    boxShadow: '0px 1px 0px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.2)',
    borderRadius: '4px',
    marginTop: '27.63px',
    marginBottom: '24px',
    padding: '8px 20px',
    outline: 'none',
    border: 0,
    color: '#232221',
    fontSize: '16px',
    lineHeight: '18px',
    fontWeight: 'bold'
  },
  loginTerm: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '17px',
    lineHeight: '130%',
    textAlign: 'center',
    color: '#656565'
  },
  dataError: {
    background: '#FF6059',
    boxShadow: '0px 1px 0px #000000, inset 0px 1px 0px rgba(255, 255, 255, 0.06)',
    borderRadius: '4px',
    width: '732px',
    padding: '8px',
    position: 'fixed',
    top: '102px',
    left: '50%',
    transform: 'translateX(-50%)',
    color: '#1C1414',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
})

const provider = new firebase.auth.GoogleAuthProvider()
class Auth extends Component {

  constructor() {
    super()
    this.history = history
  }

  state = {
    errorShow: false,
    showLogin: false
  }

  componentDidMount() {
    let _this = this
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        // User is signed in.
        if (user.email.includes('gojek.com') || user.email.includes('go-jek.com')) {
          _this.history.push({
            pathname: '/project',
            state: {
              logined: true,
              photoURL: user.photoURL,
              displayName: user.displayName,
              uid: user.uid,
              refreshToken: user.refreshToken
            }
          })
        } else {
          _this.setState({showLogin: true})
        }
      } else {
        // No user is signed in.
        _this.setState({showLogin: true})
      }
    });
  }

  handleLogin = () => {
    let _this = this
    provider.addScope('email');
    this.setState({errorShow: false})
    provider.setCustomParameters({
      prompt: 'select_account'
    })
    firebase.auth().signInWithPopup(provider).then(function(result) {
      console.log(result)
      let token = result.credential.accessToken
      localStorage.setItem('token', token)
      let user = result.user;
      if (user.email.includes('gojek.com') || user.email.includes('go-jek.com')) {
        _this.history.push({
          pathname: '/project',
          state: {
            logined: true,
            photoURL: user.photoURL,
            displayName: user.displayName,
            uid: user.uid,
            refreshToken: user.refreshToken
          }
        })
      } else {
        _this.setState({errorShow: true})
      }
    }).catch(function(error) {
      _this.setState({errorShow: true})
    });
  }

  hideError = () => {
    this.setState({errorShow: false})
  }

  render() {
    const { classes } = this.props
    const { errorShow, showLogin } = this.state
    return (
      <div className={classes.root}>
        {showLogin && <><svg width="40" height="50" viewBox="0 0 40 50" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M33 23.375V13.625C33 10.1772 31.6304 6.87058 29.1924 4.43261C26.7544 1.99464 23.4478 0.625 20 0.625C16.5522 0.625 13.2456 1.99464 10.8076 4.43261C8.36964 6.87058 7 10.1772 7 13.625V23.375C5.27609 23.375 3.62279 24.0598 2.40381 25.2788C1.18482 26.4978 0.5 28.1511 0.5 29.875V42.875C0.5 44.5989 1.18482 46.2522 2.40381 47.4712C3.62279 48.6902 5.27609 49.375 7 49.375H33C34.7239 49.375 36.3772 48.6902 37.5962 47.4712C38.8152 46.2522 39.5 44.5989 39.5 42.875V29.875C39.5 28.1511 38.8152 26.4978 37.5962 25.2788C36.3772 24.0598 34.7239 23.375 33 23.375ZM20 42.0625C17.7575 42.0625 15.9375 39.5112 15.9375 36.375C15.9375 33.2388 17.7575 30.6875 20 30.6875C22.2425 30.6875 24.0625 33.2388 24.0625 36.375C24.0625 39.5112 22.2425 42.0625 20 42.0625ZM26.5 23.375H13.5V13.625C13.5 11.9011 14.1848 10.2478 15.4038 9.02881C16.6228 7.80982 18.2761 7.125 20 7.125C21.7239 7.125 23.3772 7.80982 24.5962 9.02881C25.8152 10.2478 26.5 11.9011 26.5 13.625V23.375Z" fill="#9B9B9B"/>
        </svg>
        <button className={classes.loginBtn} id="btn-project" onClick={this.handleLogin}>Sign in with Google</button>
        <label className={classes.loginTerm}>Sign in with your Gojeck Gmail account to <br/>get access to Mospecs</label></>}
        {errorShow? <div className={classes.dataError}>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 0.625C8.1458 0.625 6.33324 1.17483 4.79153 2.20497C3.24982 3.23511 2.04821 4.69929 1.33863 6.41234C0.629062 8.1254 0.443406 10.0104 0.805142 11.829C1.16688 13.6475 2.05976 15.318 3.37088 16.6291C4.68199 17.9402 6.35246 18.8331 8.17103 19.1949C9.9896 19.5566 11.8746 19.3709 13.5877 18.6614C15.3007 17.9518 16.7649 16.7502 17.795 15.2085C18.8252 13.6668 19.375 11.8542 19.375 10C19.375 7.5136 18.3873 5.12903 16.6291 3.37087C14.871 1.61272 12.4864 0.625 10 0.625ZM10 4.41875C10.3315 4.41875 10.6495 4.55045 10.8839 4.78487C11.1183 5.01929 11.25 5.33723 11.25 5.66875C11.25 7.25625 11.2875 6.18125 11.025 11.0687C11.025 11.3456 10.915 11.6111 10.7193 11.8068C10.5236 12.0025 10.2581 12.1125 9.98125 12.1125C9.70443 12.1125 9.43895 12.0025 9.24321 11.8068C9.04747 11.6111 8.9375 11.3456 8.9375 11.0687C8.675 6.21875 8.7125 7.31875 8.7125 5.66875C8.71243 5.50139 8.74596 5.33573 8.81111 5.18157C8.87626 5.02742 8.9717 4.88792 9.09178 4.77134C9.21185 4.65476 9.35411 4.56348 9.51012 4.50292C9.66614 4.44235 9.83272 4.41373 10 4.41875ZM10 15.5813C9.72983 15.58 9.46606 15.4988 9.24193 15.348C9.0178 15.1971 8.84333 14.9833 8.74051 14.7334C8.63769 14.4836 8.61111 14.2089 8.66412 13.9439C8.71714 13.679 8.84737 13.4357 9.03841 13.2447C9.22946 13.0536 9.47277 12.9234 9.7377 12.8704C10.0026 12.8174 10.2773 12.8439 10.5272 12.9468C10.777 13.0496 10.9909 13.224 11.1417 13.4482C11.2926 13.6723 11.3738 13.9361 11.375 14.2062C11.3758 14.387 11.3408 14.5662 11.272 14.7334C11.2032 14.9006 11.102 15.0525 10.9741 15.1804C10.8463 15.3082 10.6944 15.4095 10.5272 15.4783C10.36 15.5471 10.1808 15.5821 10 15.5813Z" fill="#1C1414"/>
          </svg>
          <label className="flex-grow-1 ml-5 font-w-600">You dont seem to have used your Gojek Gamil account, try again.</label>
          <svg onClick={this.hideError} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.5297 1.53L10.4697 0.470001L5.99973 4.94L1.52973 0.470001L0.469727 1.53L4.93973 6L0.469727 10.47L1.52973 11.53L5.99973 7.06L10.4697 11.53L11.5297 10.47L7.05973 6L11.5297 1.53Z" fill="#1C1414"/>
          </svg>
        </div>:<></>}
      </div>
    )
  }
}

export default withStyles(styles, {withTheme: true})(Auth)
