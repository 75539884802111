import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import ExpanseList from './ExpanseList'
import Configs from '../configs/Configs'

const styles = theme => ({
  sectionHeader: {
    width: '100%',
    height: '25px',
    fontSize: '14px',
    background: 'linear-gradient(0deg, #121212, #121212)',
    boxShadow: '0px 1px 0px #000000, inset 0px 1px 0px rgba(255, 255, 255, 0.06)',
    position: 'relative',
    zIndex: 1
  },
  sectionHeaderInner: {
    padding: theme.spacing(0.5, 2),
    // fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '15px',
    lineHeight: '15px',
    display: 'flex',
    alignItems: 'center',
    color: '#858585'
  },
  sectionContent: {
    width: '100%',
    display: 'flex',
  },
  mainContent: {
    height: 'calc( 100vh - 92px )',
    overflowY: 'auto',
    position: 'relative'
  }
})

class GraphSection extends Component {

  constructor(props) {
    super(props)
    this.scrollList = []
    this.scrollStyle = []
    this.props.dataSet.layers.map(() => {
      this.scrollList.push(React.createRef())
      this.scrollStyle.push({fixed: false, absoluted: 0})
      return false
    })
  }

  state={
    offset: {}
  }

  handleListClick = (layerIndex, itemIndex, expandable) => {
    this.props.onSelectedList(layerIndex, itemIndex, expandable)
  }

  onScroll = (e) => {
    let currentOffset = e.target.scrollTop
    let { offset } = this.state

    // console.log(this.scrollList[0].current.offsetTop, this.scrollList[1].current.offsetTop, e.target.scrollTop)

    this.scrollList.map((list, index) => {
      if (currentOffset > offset ) {
        if(list.current.offsetTop < e.target.scrollTop && this.scrollStyle[index].absoluted === 0) {
          if(!this.scrollStyle[index].fixed) this.scrollStyle[index].fixed = true
          if (this.scrollList[index + 1] && e.target.scrollTop + 25 - this.scrollList[index + 1].current.offsetTop > 0) {
            this.scrollStyle[index].absoluted = e.target.scrollTop
          }
        }
      } else {
        if (list.current.offsetTop + 25 > e.target.scrollTop && this.scrollStyle[index].absoluted !== 0) {
          if(this.scrollList[index + 1] && this.scrollStyle[index + 1].fixed) this.scrollStyle[index + 1].fixed = false
          if (list.current.offsetTop > e.target.scrollTop) {
            this.scrollStyle[index].absoluted = 0
            this.scrollStyle[index].fixed = true
          }
        }
        if (e.target.scrollTop === 0) {
          this.scrollStyle[0].absoluted = 0
          this.scrollStyle[0].fixed = false
        }
      }
      return false
    })
    this.setState({offset : currentOffset})
  }

  render() {
    const { classes, dataSet, selectedLayerIndex, selectedItemIndex, curveStyle, animationable,  } = this.props

    return (
      <div className={classes.mainContent} onScroll={(e) => this.onScroll(e)}>
        {
          dataSet.layers.map((layer, index) => {
            return (
              <>
                <div key={index} className={classes.sectionHeader} ref={this.scrollList[index]}
                  style={{
                    position: this.scrollStyle[index].absoluted? 'absolute' : this.scrollStyle[index].fixed? 'fixed' : 'relative',
                    top: this.scrollStyle[index].absoluted? this.scrollStyle[index].absoluted + 'px' : this.scrollStyle[index].fixed? '92px' : '',
                    width: this.scrollStyle[index].fixed? (window.innerWidth - Configs.layout.rightPanelWidth - 10) + 'px' : '100%',
                    zIndex: this.scrollStyle[index].fixed? 1 : 0,
                  }}>
                  <div className={classes.sectionHeaderInner}>{layer.name}</div>
                </div>
                <div className={classes.sectionContent}>
                  <ExpanseList dataSet={layer.props} animationable={animationable} rularSet={[ Math.round(dataSet.firstKeyTime * 1000), Math.round(dataSet.lastKeyTime * 1000) ]} layerIndex={index} selectedLayerIndex={selectedLayerIndex} selectedItemIndex={selectedItemIndex} onSelected={this.handleListClick} curveStyle={curveStyle} />
                </div>
              </>
            )            
          })
        }
      </div>
    )
  }
}

export default withStyles(styles, {withTheme: true})(GraphSection)