import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemIcon, Collapse } from '@material-ui/core';
import classNames from 'classnames'

import Configs from '../configs/Configs'

const styles = theme => ({
  subListItem: {
    padding: theme.spacing(0, 2),
    paddingRight: '0px',
    height: Configs.layout.childlistHeight,
    background: '#1E1E1E',
    color: '#5F5F5F'
  },
  subListItemEven: {
    background: '#252525'
  },
  subListItemIcon: {
    color: '#5F5F5F',
    minWidth: 0,
    paddingRight: '0.3rem',
  },
  subItemName: {
    fontSize: '12px',
    textTransform: 'uppercase',
    minWidth: '56px'
  },
  subItemText: {
    fontSize: '12px',
    paddingRight: '2px',
    color: Configs.theme.color
  },
  subItemUnit: {
    fontSize: '11px',
    opacity: '0.7',
  },
  expandDropdownList: {
    paddingRight: '2px',
    boxShadow: '0px 1px 0px #000000, inset 0px 1px 0px rgba(255, 255, 255, 0.06), inset -2px 0px 0px #000000'
  }
})

class ExpanseListCollapse extends Component {
  render() {
    const { classes, open, dataSet, layerIndex, index } = this.props
    
    return (
      dataSet ?
      <Collapse in={open} timeout="auto" className={classes.expandDropdownList} unmountOnExit onClick={() => this.props.onSelectThis(layerIndex, index, false)}>
        <List component="div" disablePadding>
          <ListItem className={classes.subListItem}>
            <label className={classes.subItemName}>Start</label>
            <ListItemIcon className={classes.subListItemIcon}>
              <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.5" d="M8.75273 4.07502L5.07773 7.74182L4.13437 6.79846L6.20156 4.74768H0V3.40237H6.20156L4.15078 1.33518L5.09414 0.400024L8.75273 4.07502Z" fill="#8E8A84"/>
              </svg>
            </ListItemIcon>
            <label className={classes.subItemText}>
            {
              dataSet.startValue[0] ? Math.round(dataSet.startValue[0])/10 : dataSet.startValue
            }
            </label>
            <label className={classes.subItemUnit}>%</label>
          </ListItem>
          <ListItem className={classNames(classes.subListItem, classes.subListItemEven)}>
            <label className={classes.subItemName}>End</label>
            <ListItemIcon className={classes.subListItemIcon}>
              <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.5" d="M8.75273 4.07502L5.07773 7.74182L4.13437 6.79846L6.20156 4.74768H0V3.40237H6.20156L4.15078 1.33518L5.09414 0.400024L8.75273 4.07502Z" fill="#8E8A84"/>
              </svg>
            </ListItemIcon>
            <label className={classes.subItemText}>
            {
              dataSet.endValue[0] ? Math.round(dataSet.endValue[0])/10 : dataSet.endValue
            }
            </label>
            <label className={classes.subItemUnit}>%</label>
          </ListItem>
          <ListItem className={classes.subListItem}>
            <label className={classes.subItemName}>Dur...</label>
            <ListItemIcon className={classes.subListItemIcon}>
              <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.5" d="M8.75273 4.07502L5.07773 7.74182L4.13437 6.79846L6.20156 4.74768H0V3.40237H6.20156L4.15078 1.33518L5.09414 0.400024L8.75273 4.07502Z" fill="#8E8A84"/>
              </svg>
            </ListItemIcon>
              <label className={classes.subItemText}>
              {
                Math.round(dataSet.duration*1000)
              }
              </label>
            <label className={classes.subItemUnit}>ms</label>
          </ListItem>
          <ListItem className={classNames(classes.subListItem, classes.subListItemEven)}>
            <label className={classes.subItemName}>Delay</label>
            <ListItemIcon className={classes.subListItemIcon}>
            <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path opacity="0.5" d="M8.75273 4.07502L5.07773 7.74182L4.13437 6.79846L6.20156 4.74768H0V3.40237H6.20156L4.15078 1.33518L5.09414 0.400024L8.75273 4.07502Z" fill="#8E8A84"/>
            </svg>
            </ListItemIcon>
            <label className={classes.subItemText}>
            {
              Math.round((dataSet.startTime)*1000)
            }
            </label>
            <label className={classes.subItemUnit}>ms</label>
          </ListItem>
          <ListItem className={classes.subListItem}>
            <label className={classes.subItemName}>Easing</label>
            <ListItemIcon className={classes.subListItemIcon}>
              <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.5" d="M8.75273 4.07502L5.07773 7.74182L4.13437 6.79846L6.20156 4.74768H0V3.40237H6.20156L4.15078 1.33518L5.09414 0.400024L8.75273 4.07502Z" fill="#8E8A84"/>
              </svg>
            </ListItemIcon>
            <label className={classes.subItemText}>
              {dataSet.interpolation ? dataSet.interpolation.bezier.replace(/\(|\)/g, '').replace(/,/g, ', ') : "0, 0, 0, 0"}
            </label>
          </ListItem>
        </List>
      </Collapse> :
      <></>
    )
  }
}

export default withStyles(styles, {withTheme: true})(ExpanseListCollapse)